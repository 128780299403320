
// トースト表示 (基底)

import {App, InjectionKey} from 'vue'

import {pluginInject} from '../libs/pluginInject'

//--------------------------------------------------------------

// (型) 依存構成
export type ToastDepends = ReturnType<typeof getToastDepends>

// (型) オプション
export type ToastOptions = {}

// (型) インスタンス
export type ToastObject = ReturnType<typeof createToast>

//--------------------------------------------------------------

// (内部) インジェクションキー
export const ToastKey: InjectionKey<ToastObject> = Symbol('toast')

// (内部) 依存構成
export function getToastDepends(app: App) {
  return {}
}

// (内部) インスタンス作成
export function createToast(depends?: ToastDepends, options?: ToastOptions) {
  return {
    show(severity: string, ...args: any[]) {},

    info   (...args: any[]) {this.show('info',    ...args)},
    success(...args: any[]) {this.show('success', ...args)},
    warn   (...args: any[]) {this.show('warn',    ...args)},
    error  (...args: any[]) {this.show('error',   ...args)},
    update (...args: any[]) {this.show('update',  ...args)},
  }
}

//--------------------------------------------------------------

// プラグインインストール
export const ToastPlugin = {
  install(app: App, options?: ToastOptions) {
    app.provide(ToastKey, createToast(getToastDepends(app), options))
  },
}

// プラグイン取得
export function useToast() {
  return pluginInject(ToastKey)
}
