<template lang="pug">
q-select.x-year-month(
  ref="elQSelect"
  v-bind="$attrs"
  label="年月"
  dense standout="aq-field-standout"
  emit-value map-options
  :options="options"
  v-model="text"
)
  template(#append)
    q-icon.cursor-pointer(
      v-if="!noClearable && text"
      name="cancel"
      @click.stop.prevent="onClear"
    )
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, watch, onMounted} from 'vue'
import type {QSelect} from 'quasar'
import {format, startOfMonth, subMonths, isBefore, addMonths} from 'date-fns'

// props

const props = withDefaults(defineProps<{
  modelValue: string|null,
  noClearable?: boolean,
  addMonth?: number,
}>(), {
  noClearable: false,
  addMonth: 0,
})

// emits

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|null): void,
}>()

// state

const elQSelect = ref<QSelect|null>(null)
const text = ref<string|null>(props.modelValue)

// events

const minMonth = ref(startOfMonth(new Date('2021-01')))
const maxMonth = ref(startOfMonth(new Date))

const options = computed(() => {
  const options = []
  let month = maxMonth.value
  while(!isBefore(month, minMonth.value)) {
    options.push({
      value: format(month, 'yyyyMM'),
      label: format(month, 'yyyy-MM'),
    })
    month = subMonths(month, 1)
  }
  return options
})

onMounted(async () => {
  if(props.noClearable && props.modelValue == null) {
    emit('update:modelValue', format(addMonths(new Date, props.addMonth), 'yyyyMM'))
  }
})

watch(() => props.modelValue, value => {
  text.value = value
})

watch(() => text.value, value => {
  emit('update:modelValue',
    props.noClearable && value == null
      ? format(new Date, 'yyyyMM')
      : value
  )
})

async function onClear(ev: Event) {
  text.value = null
}
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.x-year-month
  width: 144px
</style>
