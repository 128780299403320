<template lang="pug">

q-select(
  ref="elSelect"
  v-bind="$attrs"
  filled options-dense
  :modelValue="modelValue"
  @update:modelValue="v => {emit('update:modelValue', v)}"

  emit-value map-options
  :loading="false"
  :options="options"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'
import type {QSelect} from 'quasar'
import Axios from 'axios'

import {useAxios} from '@/aax/plugins/Axios'

type LooseDictionary = {[x: string]: any}

// injects

const axios = useAxios().$scoped()

// props

const props = withDefaults(defineProps<{
  modelValue:    string|number|null,

  // 遅延データ読込
  apiUrl:        string,
  offsetMethod?: 'cursor'|'offset',
  rowKey?:       string,
  labelKey?:     string|((row: LooseDictionary) => any),
  searchKey?:    string,
  rowsPerLoad?:  number,

}>(), {

  // 遅延データ読込
  offsetMethod:  'cursor',
  rowKey:        'id',
})

// emits

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|number|null): void,
}>()

// ref

const elSelect = ref<InstanceType<typeof QSelect>>()

// 遅延データ読込

const rows = ref<any[]>([])
const loading = ref(false)
const first = ref(true)
const bottom = ref(0)
const total = ref<number|null>(null)

const options = computed(() => {
  // rowsを読み込む前は空ラベルにしておく
  if (first.value) {
    return [{ value: props.modelValue, label: ' ' }]
  }

  const {labelKey} = props

  const getLabel = labelKey == null
    ? (row: any) => row[props.rowKey]
    : (labelKey instanceof Function
      ? (row: any) => labelKey(row)
      : (row: any) => row[labelKey]
    )

  return rows.value
    .filter(row =>
      row.deleted == null ||
      row[props.rowKey] === props.modelValue)
    .map(row => ({
      value: row[props.rowKey],
      label: getLabel(row),
    }))
})

onBeforeMount(loadFirstRows)

async function loadFirstRows() {
  axios.$cancel()

  rows.value = []
  bottom.value = 0
  total.value = null

  await loadRows()
  first.value = false
}

async function loadRows() {
  try {
    const data = await axios.$get<any[]>(props.apiUrl)
    rows.value = data
  } catch(e) {
    if(!Axios.isCancel(e)) {
      total.value = rows.value.length
      throw e
    }
  }
}
</script>
