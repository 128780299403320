
<!-- uncarriedShortage 繰越されない未払い項目 [一覧] -->

<template lang="pug">
//- 設定
x-list(
  :columns          = "columns"
  api-url           = "/_/api/uncarried-shortages"
  row-key           = "id"
  selection         = "none"
  :additional-query = "additionalQuery"
  eager
)
  template(#header-right)
    x-btn(
      color="primary"
      @click="openPrintable"
    ) 印刷用
    x-target-client(v-model="searchClientId" dense no-clearable)
    x-year-month(v-model="searchYearMonth" dense no-clearable)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed} from 'vue'
import {format, parse, parseISO, isValid, startOfMonth} from 'date-fns'
import {getIntQuery, getStringQuery} from '@/aax/libs/vue-router'
import type {ColumnsT} from '@/aax/components-quasar/AqLazyTable.vue'

// injects

import {useRoute} from 'vue-router'

const route = useRoute()

// TODO: どうする？ 前月以前は…

const currentDate = ref(startOfMonth(new Date))

// const disable = computed(() => {
//   if(searchYearMonth.value == null) return true
//   const minimumDate = parse(searchYearMonth.value, 'yyyyMM', 0)
//   return !isValid(minimumDate) || isBefore(minimumDate, currentDate.value)
// })

// 絞り込み

const searchYearMonth = ref(getStringQuery(route.query.month))
const searchClientId = ref(getIntQuery(route.query.client_id))

const additionalQuery = computed(() => {
  return (searchYearMonth.value == null || searchClientId.value == null) ? false : {
    month: searchYearMonth.value,
    client_id: searchClientId.value,
  }
})

// カラムUI定義
const columns: ColumnsT = [
  {
    label: '繰越元相殺日',
    name: 'carried',
    sortable: true,
    field: row => formatDate(row['carried']),
    headerClasses: 'q-table--col-auto-width',
  },
  {
    label: '相殺日',
    name: 'processed',
    sortable: true,
    field: row => formatDate(row['processed']),
    headerClasses: 'q-table--col-auto-width',
  },
  {
    label: '相殺項目',
    name: 'clientOffsetName',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },
  {
    label: '生産者',
    name: 'producerName',
    sortable: true,
    field: row => {
      return String(row.clientId) === route.query.client_id
        ? `${row['producerId']} ${row['producerName']}`
        : `${row['producerId']} ${row['producerName']}【${row['clientName']}】`
    },
  },
  {
    label: '個数',
    name: 'quantity',
    align: 'right',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },
  {
    label: '金額',
    name: 'value',
    align: 'right',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },
  {
    label: '計',
    name: 'quantity_value',
    sortable: true,
    align: 'right',
    headerClasses: 'q-table--col-auto-width',
    field: row => (row.quantity ?? 0) * (row.value ?? 0),
  },
  {
    label: '備考',
    name: 'remarks',
    sortable: true,
  },
]

function formatDate(str: string) {
  const obj = parseISO(str)
  return !isValid(obj) ? '' : format(obj, 'yyyy-MM-dd')
}

function openPrintable() {
  const month = route.query.month as string
  const client_id = route.query.client_id as string

  const dateObj = parse(month, 'yyyyMM', 0)
  const dateStr = isValid(dateObj) ? format(dateObj, '_yyyy_MM') : ''
  const filename = `${client_id}${dateStr}`

  window.open(`/_/uncarried-shortages/${month}/${client_id}/${filename}.pdf`, '_blank')
}
</script>
