<template lang="pug">
q-page.overflow-hidden(
  v-if="!isProducer"
  :style-fn="styleFnQPage"
)
  q-toolbar.absolute.aq-page__header(:class="darkClass")
    slot(name="header-raw")
      .row.items-center.q-gutter-sm
        slot(name="header-left")
          q-btn(
            dense flat round
            icon="home"
            @click="onClickHome"
          )

      slot(name="header-title")
        .aq-page-x-title.non-selectable {{finalTitle}}

      .row.aq-page-x-ui.justify-center.items-center.q-gutter-sm
        slot(name="header-right")

    .aq-page__header-shadow.absolute-full.overflow-hidden.no-pointer-events(v-if="elevated" :class="darkClass")
    q-resize-observer(@resize="onResize")

  slot(name="content-raw" :headerHeight="headerHeight")
    .absolute-full(v-if="fixed" :style="{top: headerHeight + 'px'}")
      slot
      q-inner-loading.aq-page__loading(:showing="loading")

    q-scroll-area.full-height(v-else @scroll="onScroll")
      .full-height(:style="{paddingTop: headerHeight + 'px'}")
        slot
        q-inner-loading.aq-page__loading(:showing="loading")
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed} from 'vue'
import {useRouter} from 'vue-router'
import {useQuasar} from 'quasar'
import type {QScrollAreaOnScrollInfo} from '@/aax/components-quasar/AqDefines'
import {useAuth} from '../plugins/Auth'

const router = useRouter()
const quasar = useQuasar()
const auth = useAuth()

const props = withDefaults(defineProps<{
  title?:    string,
  elevated?: boolean,
  fixed?:    boolean,
  loading?:  number|boolean,
}>(), {
  elevated: true,
  fixed:    false,
})

const emit = defineEmits<{
  (e: 'scroll', info: QScrollAreaOnScrollInfo): void,
}>()

// Darkテーマ
const darkClass = computed(() => 'aq-page' + (quasar.dark.isActive ? '--dark' : '--light'))

// QPageのスタイルを計算する
function styleFnQPage(offset: number) {
  return {height: offset ? 'calc(100vh - ' + offset + 'px)' : '100vh'}
}

// タイトル文字列
const routes = router.getRoutes()

const finalTitle = computed(() => {
  if(props.title != null) return props.title

  const route = router.currentRoute.value
  const title = route.meta?.title
  if(title != null) return title

  const parent = route.meta?.parent
  if(parent != null) {
    const title = routes.find(route => route.name === parent)?.meta?.title
    if(title != null) return title
  }

  return import.meta.env.VITE_TITLE
})

// headerの高さを監視する
const headerHeight = ref(50)

function onResize({height}: {height: number}) {
  headerHeight.value = height
}

// イベントを受け渡す
function onScroll(info: QScrollAreaOnScrollInfo) {
  emit('scroll', info)
}

// ローディング表示
const loading = computed(() => Boolean(props.loading))

// ホームへ
function onClickHome() {
  router.push({name: 'menu'})
}

// 権限が「生産者」なら「PaymentStatement 支払明細」に強制遷移する
const isProducer = computed(() => auth.hasRoles('producer'))
if(isProducer.value && router.currentRoute.value.name !== 'paymentStatementsList') {
  router.replace({name: 'paymentStatementsList'})
}
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.aq-page__header,
.aq-page__header-shadow
  z-index: 2000

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))
  .aq-page__header.aq-page--light
    background-color: darken(white, 10%)
  .aq-page__header.aq-page--dark
    background-color: lighten($dark-page, 10%)

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))
  .aq-page__header
    backdrop-filter: blur(6px)
  .aq-page__header.aq-page--light
    background-color: rgba(darken(white, 20%), .5)
  .aq-page__header.aq-page--dark
    background-color: rgba(lighten($dark-page, 20%), .5)

.aq-page__header-shadow
  bottom: -10px

.aq-page__header-shadow::after
  bottom: 10px
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2), 0 0 5px rgba(0, 0, 0, .24)
  content: ""
  left: 0
  position: absolute
  right: 0
  top: 0

.aq-page__loading
  z-index: 1000

.aq-page-x-title
  min-width: 1px
  max-width: 100%
  font-size: 21px
  font-weight: normal
  letter-spacing: 0.01em
  padding: 0 12px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.aq-page-x-ui
  flex-grow: 1
</style>
