
// axiosプラグイン

import {onBeforeUnmount, provide, App, InjectionKey} from 'vue'
import Axios, {AxiosRequestConfig, AxiosInstance, AxiosError} from 'axios'

import Console                         from '../libs/consoleOverride'
import {pluginInject, pluginInjectApp} from '../libs/pluginInject'

// 依存プラグイン
import {ErrorHandlerKey} from './ErrorHandler'
import {EventBusKey}     from './EventBus'
import {ToastKey}        from './Toast'

//--------------------------------------------------------------

// (型) 依存構成
export type AxiosDepends = ReturnType<typeof getAxiosDepends>

// (型) オプション
export type AxiosOptions = AxiosRequestConfig

// (型) インスタンス
export type AxiosObject = AxiosInstance & {
  $scoped(config?: AxiosOptions): AxiosObject,
  $cancel(): void,

  $request<T = any>(config: AxiosRequestConfig): Promise<T>,
  $get    <T = any>(url: string, config?: AxiosRequestConfig): Promise<T>,
  $delete <T = any>(url: string, config?: AxiosRequestConfig): Promise<T>,
  $head   <T = any>(url: string, config?: AxiosRequestConfig): Promise<T>,
  $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>,
  $post   <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>,
  $put    <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>,
  $patch  <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>,
}

//--------------------------------------------------------------

// (内部) インジェクションキー
export const AxiosKey: InjectionKey<AxiosObject> = Symbol('axios')

// (内部) 依存構成
export function getAxiosDepends(app: App) {
  return {
    eventBus:     pluginInjectApp(app, EventBusKey),
    toast:        pluginInjectApp(app, ToastKey),
    errorHandler: pluginInjectApp(app, ErrorHandlerKey),
  }
}

// (内部) インスタンス作成
export const eventUnauthorized       = Symbol('axios-unauthorized')
export const eventServiceUnavailable = Symbol('axios-service-unavailable')

export function createAxios(depends: AxiosDepends, options?: AxiosOptions) {
  const {eventBus, toast, errorHandler} = depends

  errorHandler.addHandler((err, next) => {

    // nullの場合はスルー
    // Dialog__quasar のキャンセルはこの応答になる
    if(err == null) {
      return 1
    }

    // axiosのキャンセルの場合はスルー
    if(Axios.isCancel(err)) {
      Console.former.warn('通信がキャンセルされました')
      return 1
    }

    // axiosのエラーの場合
    if(Axios.isAxiosError(err)) {
      Console.former.error(err) // eslint-disable-line no-console

      // 接続できない場合
      if(err.response == null) {
        toast.show('error', '通信エラーが発生しました')
        return 1
      }

      // 認証無効
      if(err.response.status === 401 || err.response.status === 419) {
        eventBus.emit(eventUnauthorized, err.request?.responseURL)
        return 1
      }

      // メンテナンスモード
      // TODO: 元々Laravel に対応したもの, ここの実装は再検討が必要
      if(err.response.status === 502 || err.response.status === 503) {
        eventBus.emit(eventServiceUnavailable, err.request?.responseURL)
        return 1
      }

      // サーバーバリデーション違反応答の場合
      // TODO: 元々Laravel に対応したもの, ここの実装は再検討が必要
      // if(err.response.status === 422 && err.response.data != null) {
      //   if(err.response.data.errors) {
      //     return () => {
      //       onValidationFail(err.response.data.errors)
      //     }
      //   }
      //   if(err.response.data.message) {
      //     return () => {
      //       toast.show('error', err.response.data.message)
      //     }
      //   }
      // }

      // それ以外のサーバーエラーコード
      if(!import.meta.env.PROD) {
        toast.show('error',
          'サーバーエラー' + err.response.status +
          ' ' + JSON.stringify(err.response.data).substring(0, 200)
        )
        return 1
      }

      toast.show('error', 'サーバーエラー' + err.response.status + 'が発生しました')
      return 1
    }

    next()
  })

  // オブジェクト作成
  const createObject = (config: AxiosOptions) => {
    const obj = Axios.create(config) as AxiosObject

    // コンポーネント寿命スコープをもつクローンを作成
    obj.$scoped = configNew => {
      const obj = createObject({...config, ...configNew})
      onBeforeUnmount(() => {obj.$cancel()})
      provide(AxiosKey, obj)
      return obj
    }

    // キャンセル
    let cancelTokenSource = Axios.CancelToken.source()

    obj.interceptors.request.use(config => {
      if(!config.cancelToken) {
        config.cancelToken = cancelTokenSource.token
      }
      return config
    })

    obj.$cancel = () => {
      cancelTokenSource.cancel()
      cancelTokenSource = Axios.CancelToken.source()
    }

    // dataを直接返すリクエスト
    for(const name of ['request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch']) {
      (obj as any)['$' + name] = async (...args: any[]) => (await (obj as any)[name](...args)).data
    }

    return obj
  }

  return createObject({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },

    ...options,
  })
}

//--------------------------------------------------------------

// プラグインインストール
export const AxiosPlugin = {
  install(app: App, options?: AxiosOptions) {
    app.provide(AxiosKey, createAxios(getAxiosDepends(app), options))
  },
}

// プラグイン取得
export function useAxios() {
  return pluginInject(AxiosKey)
}

// axiosエラーステータスをテストする
export function testAxiosError(e: any, statusCodes: number[]|number): e is AxiosError {
  if(!Array.isArray(statusCodes)) statusCodes = [statusCodes]
  return Axios.isAxiosError(e) && e.response != null && statusCodes.includes(e.response.status)
}
