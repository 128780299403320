
<template lang="pug">
q-layout(view="lHr LpR lFr")
  q-drawer(
    v-model="open"
    elevated overlay no-swipe-open no-swipe-close no-swipe-backdrop
    behavior="mobile"
    :width="width"
    @click="setOpen(false)"
  )
    .full-height.column.no-wrap.overflow-hidden
      slot(name="top")
        q-item.non-selectable.aq-root-drawer__top
          q-item-section

      q-separator

      q-scroll-area.full-height
        aq-root-menu-item(
          v-for="route of visibleRoutes"
          :key="route.name"
          :icon="route.meta?.icon"
          :title="route.meta?.title"
          :active="route.name === currentRouteName"
          @click="select(route, $event)"
        )

        slot(name="append")

      q-separator

      slot(name="bottom")

  q-page-container
    NotFound(v-if="hideRoute")
    router-view(v-else)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import AqRootMenuItem from '@/aax/components-quasar/AqRootMenuItem.vue'

import {ref, computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {useRouter} from 'vue-router'
import type {RouteRecordRaw} from 'vue-router'

import {useEventBus} from '@/aax/plugins/EventBus'
import {AqRootMenuBtnKey} from '@/aax/components-quasar/AqDefines'

import {useAuth} from '../plugins/Auth'
import type {Role} from '@/types/plugins/Auth'
import NotFound from '@/views/notFound.vue'

const auth = useAuth()
const router = useRouter()
const eventBus = useEventBus()

const props = withDefaults(defineProps<{
  width?: number,
}>(), {
  width: 200,
})

// QDrawerの開閉
const open = ref(false)

function setOpen(value: boolean) {
  open.value = value
}

onBeforeMount(() => {
  eventBus.on(AqRootMenuBtnKey, () => {
    open.value = !open.value
  })
})

onBeforeUnmount(() => {
  eventBus.off(AqRootMenuBtnKey)
})

// routesの取得
const visibleRoutes = computed(() => {
  const visibleRoutes = []
  for(const route of router.options.routes) {

    // roleをチェックする
    const roles = route.meta?.roles as Role[]|undefined
    if(roles != null && !auth.hasRoles(...roles)) continue

    //
    if(route.meta?.title) {
      visibleRoutes.push(route)
    }
  }
  return visibleRoutes
})

const currentRouteName = computed(() => {
  const route = router.currentRoute.value
  return route.meta?.parent ?? route.name
})

async function select(route: RouteRecordRaw, ev: MouseEvent) {
  if(ev.shiftKey) {
    const {href} = router.resolve({name: route.name})
    setTimeout(() => {window.open(href, '_blank')}, 0)
  }
  else if(route.name !== currentRouteName.value) {
    await router.push({name: route.name})
  }
}

// roleをチェックする
const hideRoute = computed(() => {
  let route = router.currentRoute.value
  while(route.meta?.parent) route = router.resolve({name: route.meta?.parent})
  const roles = route.meta?.roles as Role[]|undefined
  return roles != null && !auth.hasRoles(...roles)
})

</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.aq-root-drawer__top
  background-color: rgba(128, 128, 128, .2)
  height: 50px
</style>
