
<!-- PaymentStatement 支払予定合計表 [一覧] -->

<template lang="pug">
//- 設定
x-list(
  :columns          = "columns"
  api-url           = "/_/api/payment-statements"
  api-count-url     = "/_/api/payment-statements/@count"
  row-key           = "id"
  :rows-per-load    = "100"
  selection         = "multiple"
  v-model:selected  = "selected"
  :additional-query = "additionalQuery"
  :menus            = "menus"
  @row-update       = 'onRowUpdate'
)
  template(#header-right)
    x-btn(
      v-if="auth.hasRoles('admin','client') && additionalQuery.month"
      color="primary"
      @click="openPrintable"
    ) 印刷用
    x-target-client(v-model="searchClientId" dense)
    x-year-month(v-model="searchYearMonth" dense)
    x-payment-statement-list-filter(v-model="searchWithoutZero")
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed} from 'vue'

import {format, parseISO, isValid} from 'date-fns'

import {getStringQuery, getIntQuery} from '@/aax/libs/vue-router'
import type {ColumnsT, SelectedT} from '@/aax/components-quasar/AqLazyTable.vue'
import XPaymentStatementListFilter from '@/components/XPaymentStatementListFilter.vue'
import type { Action } from '@/components/XList.vue'

import {List} from '@/types/apis/PaymentStatements'

// injects

import {useRoute} from 'vue-router'
import {useAuth} from '@/plugins/Auth'
import {postOpen} from '@/libs/postOpen'

const route = useRoute()
const auth = useAuth()

// カラムUI定義
const columns: ColumnsT = [
  {
    label: '代表生産者ID',
    name: 'primaryProducerId',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },  // Int


  {
    label: '代表生産者名',
    name: 'producerName',
  },  // Int

  {
    label: '団体ID',
    name: 'clientId',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },  // Int

  {
    label: '団体名',
    name: 'clientName',
  },  // Int

  {
    label: '支払金額',
    name: 'amount',
    headerClasses: 'q-table--col-auto-width',
  },  // Int?

  // {
  //   label: '明細ファイル名',
  //   name: 'filename',
  // },  // String?

  {
    label: '支払明細作成日',
    name: 'processed',
    sortable: true,
    field: row => formatDate(row['processed']),
    headerClasses: 'q-table--col-auto-width',
  },  // DateTime?
]

function formatDate(str: string) {
  const obj = parseISO(str)
  return !isValid(obj) ? '' : format(obj, 'yyyy-MM-dd')
}

// 絞り込み
const searchYearMonth = ref(getStringQuery(route.query.month))
const searchClientId = ref(getIntQuery(route.query.client_id))
const searchWithoutZero = ref(getIntQuery(route.query.nz))

const additionalQuery = computed(() => {
  return {
    month: searchYearMonth.value,
    client_id: searchClientId.value,
    nz: searchWithoutZero.value,
  }
})

//
const selected = ref<SelectedT>([])

const menus = computed(() => {
  const menus: Action[] = [
    {action: 'update', label: '開く', icon: 'article'},
  ]
  if ( parseInt((searchYearMonth?.value ?? '')) >= 202402) {
    menus.push({action: 'update', label: 'CSVを開く', icon: 'system_update_alt', on: onOpenCSV})
  }
  if (1 < (selected.value?.length ?? 0)) {
    menus.splice(1, 0, {label: 'まとめて開く', icon: 'picture_as_pdf', on: onOpenMulti})
    if ( parseInt((searchYearMonth?.value ?? '')) >= 202402) {
      menus.splice(2, 0, {label: 'まとめてCSVを開く', icon: 'system_update_alt', on: onOpenCSVMulti})
    }
  }
  return menus
})

function onRowUpdate(row: List.RowT) {
  const filepath = row.filename?.match(/\/\d+-\d+\.pdf$/) ? row.filename?.replace(/\/(\d+)-(\d+).pdf$/, '/$1/$2') : row.filename?.replace(/.pdf$/, '')

  const producerName = row.primaryProducerId

  const dateObj = new Date(row.processed as any)
  const dateStr = isValid(dateObj) ? format(dateObj, '_yyyy_MM') : ''
  const filename = `${producerName}${dateStr}`

  window.open(`/_/payment-statements/${filepath}/${filename}.pdf`, '_blank')
}

function openPrintable() {
  const query: any = {}
  for(const [key, value] of Object.entries(additionalQuery.value)) {
    if(value != null) {
      query[key] = value
    }
  }

  const searchParams = new URLSearchParams(query)
  window.open(`/_/payment-statements-list-printable?${searchParams.toString()}`, '_blank')
}

function onOpenMulti() {
  const rows: List.RowT[] = selected?.value ?? []
  postOpen('/_/payment-statements-multi', {files: rows.map((row) => row.filename?.replace(/.pdf$/, ''))})
}
function onOpenCSV() {
  const rows: List.RowT[] = selected?.value ?? []
  postOpen('/_/payment-statements-csv', {files: rows.map((row) => row.filename?.replace(/.pdf$/, ''))})
}
function onOpenCSVMulti() {
  const rows: List.RowT[] = selected?.value ?? []
  postOpen('/_/payment-statements-csv-multi', {files: rows.map((row) => row.filename?.replace(/.pdf$/, ''))})
}
</script>
