
// Javascriptエラーハンドリング

import {App} from 'vue'

import Console           from '../libs/consoleOverride'
import {pluginInjectApp} from '../libs/pluginInject'

// 依存プラグイン
import {ReportKey} from './Report'
import {ToastKey}  from './Toast'

//--------------------------------------------------------------

// (型) オプション
export type JsHooksOptions = {
  errorEvent?(ev: ErrorEvent): any
  unhandledrejectionEvent?(ev: PromiseRejectionEvent): any
  rejectionhandledEvent?(ev: PromiseRejectionEvent): any
}

//--------------------------------------------------------------

// (内部) 依存構成
export function getJsHooksDepends(app: App) {
  return {
    toast:  pluginInjectApp(app, ToastKey),
    report: pluginInjectApp(app, ReportKey),
  }
}

//--------------------------------------------------------------

// プラグインインストール
export const JsHooksPlugin = {
  install(app: App, options?: JsHooksOptions) {
    const {toast, report} = getJsHooksDepends(app)

    Console.override('error', (...args) => {
      Console.former.error(...args)
      report.submit('error', ...args)
    })

    Console.override('warn', (...args) => {
      Console.former.warn(...args)
      report.submit('warn', ...args)
    })

    const hooks: Required<JsHooksOptions> = {
      errorEvent(ev: ErrorEvent) {
        report.submit('error', 'ErrorEvent', ev.message, ev.filename, ev.lineno, ev.colno, ev.error)
      },

      unhandledrejectionEvent(ev: PromiseRejectionEvent) {
        report.submit('error', 'unhandledrejection', ev.reason)
      },

      rejectionhandledEvent(ev: PromiseRejectionEvent) {
        report.submit('error', 'rejectionhandled', ev.reason)
      },

      ...options,
    }

    window.addEventListener('error',              hooks.errorEvent)
    window.addEventListener('unhandledrejection', hooks.unhandledrejectionEvent)
    window.addEventListener('rejectionhandled',   hooks.rejectionhandledEvent)

    // 開発環境のみ: vue vite hmr 更新を通知
    if(import.meta.env.DEV) {
      Console.override('log', (...args) => {
        Console.former.log(...args)
        if(typeof args[0] === 'string' && args[0].startsWith('[vite] hot updated:')) {
          toast.show('update', '更新しました')
        }
      })
    }
  },
}
