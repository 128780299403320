
import {App, InjectionKey, ref, computed} from 'vue'

import {pluginInject, pluginInjectApp} from '../libs/pluginInject'
import {sleep}                         from '../libs/sleepAsync'

// 依存プラグイン
import {AxiosKey, eventUnauthorized} from './Axios'
import {EventBusKey}                 from './EventBus'
import {ToastKey}                    from './Toast'

// コンポーネント
import AuthGuard from '../components/AuthGuard.vue'

//--------------------------------------------------------------

// (型) 依存構成
export type AuthDepends = ReturnType<typeof getAuthDepends>

// (型) オプション
export type AuthOptions = {
  introPath?:  string,
  loginPath?:  string,
  logoutPath?: string,
}

// (型) インスタンス
export type AuthObject = ReturnType<typeof createAuth>

// (型) key-value型
export type KeyValue = {
  [key: string]: string,
}

// (型) 認証データ型
export type AuthData = KeyValue & {
  ver?:  string,
  auth?: KeyValue,
}

//--------------------------------------------------------------

// (内部) インジェクションキー
export const AuthKey: InjectionKey<AuthObject> = Symbol('auth')

// (内部) 依存構成
export function getAuthDepends(app: App) {
  return {
    app,
    eventBus: pluginInjectApp(app, EventBusKey),
    toast:    pluginInjectApp(app, ToastKey),
    axios:    pluginInjectApp(app, AxiosKey),
  }
}

// (内部) インスタンス作成
export function createAuth(depends: AuthDepends, options?: AuthOptions) {
  const {app, eventBus, toast, axios} = depends

  // オプションのデフォルト値を適用する
  const final: Required<AuthOptions> = {
    introPath:  '/_/api/intro',
    loginPath:  '/_/api/login',
    logoutPath: '/_/api/logout',

    ...options,
  }

  //
  let state = ref<AuthData>({})
  let loading = false

  const obj = {

    ver:  computed(() => state.value.ver),
    auth: computed(() => state.value.auth),

    async load() {
      if(loading !== true) {
        loading = true
        try {
          state.value = await axios.$get(final.introPath)
        }
        finally {
          loading = false
        }

        // バージョン出力
        if(import.meta.env.PROD) {
          console.log('version', state.value.ver ?? 'N/A')
        }
      }
    },

    async login(params?: KeyValue) {
      try {
        state.value = await axios.$post(final.loginPath, params)
      }
      catch(e: any) {
        if(![401, 422].includes(e?.response?.status)) {
          throw e
        }
        toast.show('error', 'パスワードが一致しません')
      }
    },

    async logout() {
      state.value = await axios.$post(final.logoutPath)
    },
  }

  eventBus.on(eventUnauthorized, async () => {
    try {
      toast.show('error', 'ログアウトしました')
      await obj.load()
    }
    catch {
      delete state.value.auth
    }
  })

  // コンポーネント
  app.component('AuthGuard', AuthGuard)

  return obj
}

// (内部) 初期情報取得
export async function loadIntro(obj: AuthObject) {
  for(;;) {
    try {
      await obj.load()
      break
    }
    catch {
      await sleep(1000)
    }
  }
  return obj
}

//--------------------------------------------------------------

// プラグインインストール
export async function loadAuthPlugin(app: App, options?: AuthOptions) {
  const obj = createAuth(getAuthDepends(app), options)
  await loadIntro(obj)

  return {
    install(app: App) {
      app.provide(AuthKey, obj)
    },
  }
}

// プラグイン取得
export function useAuth() {
  return pluginInject(AuthKey)
}
