
<!-- テーブル形式フォームのカラム入力: 相殺項目選択 -->

<template lang="pug">
q-select.x-col-client-offset(
  dense standout="aq-field-standout"
  options-dense
  emit-value map-options
  :class="{'x-col-client-offset__loading': loading, 'x-col__error': error}"
  :loading="loading"
  :modelValue="modelValue"
  :options="options"
  @update:modelValue="onUpdate"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, watchEffect} from 'vue'

import {testRules} from './XColRules'
import type {Rules} from './XColRules'
import {ClientOffsetSelect} from '@/types/apis/ClientOffsets'

// inject

import {useClientOffsetData} from './XColClientOffsetDataProvider'

const clientOffsetData = useClientOffsetData()

// props

const props = defineProps<{
  modelValue: string|null,
  rules?: Rules,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|null): void,
  (e: 'update', data: ClientOffsetSelect.RowT): void,
}>()

// バリデーション

const error = ref(false)

watchEffect(async () => {
  error.value = !await testRules(props.rules, props.modelValue)
})

// state

const clients = computed(() => clientOffsetData.value?.map(row => ({
  value: row.id,
  label: row.name ?? '',
})) ?? [])
const loading = computed(() => clientOffsetData.value == null)

const filter = ref('')
const options = computed(() =>
  filter.value === ''
    ? clients.value
    : clients.value.filter(c => c.label.includes(filter.value))
)

// events

async function onUpdate(value: string|null) {
  emit('update:modelValue', value)

  const data = clientOffsetData.value?.find(v => v.id === value)
  if (data != null) {
    emit('update', data)
  }
}
</script>

<!----------------------------------------------------------------------------->

<script lang="ts">
import '@/aax/components-quasar/AqStyles.sass'
</script>

<style lang="sass">
.x-col-client-offset
  max-width: 144px !important

  .q-field__native
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &.x-col-client-offset__loading
    .q-field__native
      visibility: hidden
</style>
