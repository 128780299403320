
<!-- clientOffsets 相殺項目 -->

<template lang="pug">
aq-page(fixed :loading="loading")
  template(#header-right)
    q-btn(
      label="保存"
      :color="notTouch ? 'grey' : 'primary'"
      :disable="notTouch"
      @click="onSave"
    )

  .column.no-wrap.full-height.q-ma-xs
    .row.no-wrap.items-center.q-mb-xs.client-offsets__header
      q-icon.client-offsets__drag-handle.q-mr-xs.invisible(
        dense flat size="sm"
        icon="drag_handle"
      )

      x-col-header.col-3.q-mr-xs 名称
      x-col-header.col.client-offsets__col-intax.q-mr-xs 税
      x-col-header.col.client-offsets__col-carry.q-mr-xs 繰越

      q-btn.q-mr-xs(
        dense flat size="sm"
        icon="add"
        @click="onAdd"
      )

    .row.no-wrap.items-center.full-height.relative-position
      q-scroll-area.absolute-full
        .column.no-wrap
          draggable(
            v-model="data"
            handle=".client-offsets__drag-handle"
            item-key="id"
          )
            template(#item="{element: item, index}")
              .row.items-center.no-wrap.q-mb-xs(:key="item.id")
                  q-icon.client-offsets__drag-handle.q-mr-xs(
                    size="sm"
                    name="drag_handle"
                    style="height: 100%"
                  )

                  x-col-text.col-3.q-mr-xs(
                    v-model="item.name"
                    :rules="[validations.name]"
                    :maxlength="250"
                  )

                  .row.col.client-offsets__col-intax.q-mr-xs
                    q-btn-toggle(
                      v-model="item.inTax"
                      :options="optionInTax"
                    )

                  .row.col.client-offsets__col-carry.q-mr-xs
                    q-btn-toggle(
                      v-model="item.carry"
                      :options="optionInCarry"
                    )

                  q-btn.q-mr-xs(
                    dense flat size="sm"
                    icon="delete" text-color="red"
                    tabindex="-1"
                    @click="onDelete(index)"
                  )
</template>

<!----------------------------------------------------------------------------->

<script lang="ts">
import {ref, computed, onBeforeMount, nextTick} from 'vue'
import draggable from 'vuedraggable'
import {QVirtualScroll} from 'quasar'
import {cloneDeep, isEqual} from 'lodash-es'

import {Put} from '@/types/apis/ClientOffsetsView'

// injects

import {useAxios, testAxiosError} from '@/aax/plugins/Axios'
import {useDialog} from '@/aax/plugins/Dialog'
</script>

<script setup lang="ts">

// injects

const axios = useAxios().$scoped()
const dialog = useDialog()

// データ

const data = ref<Put.RowT[]>([])
const loading = ref(false)

const original = ref<Put.RowT[]>([])
const notTouch = computed(() => isEqual(data.value, original.value))

let lastUrl: string|undefined

function getApiUrl() {
  return '/_/api/client-offsets/@view'
}

async function loadData(force: boolean = false) {
  const url = getApiUrl()
  if(url != null && (force || url !== lastUrl)) {
    lastUrl = url
    try {
      loading.value = true
      data.value = []
      original.value = []
      data.value = await axios.$get(url)
      original.value = cloneDeep(data.value)
    }
    finally {
      loading.value = false
    }
  }
}

onBeforeMount(async () => {
  await Promise.all([
    loadData(),
  ])
})

// 保存

async function onSave() {
  if(!validateAll()) {
    await dialog.alert({message: 'シートを正しく記入してください'})
    return
  }

  for(const [index, row] of data.value.entries()) {
    row.sort = index
  }

  const url = getApiUrl()
  if(url != null) {
    try {
      loading.value = true
      await axios.$put(url, data.value)
    }
    catch(e) {
      if(!testAxiosError(e, [400, 404])) throw e
      await dialog.alert({message: 'このシートの更新は締め切られています'})
      return
    }
    finally {
      loading.value = false
    }
  }
  await loadData(true)
}

// 行を追加

const elVirtualScrollRef = ref<QVirtualScroll|null>(null)

async function onAdd() {
  data.value.unshift({
    name:  '',
    sort:  0,
    inTax: true,
    carry: false,
  })

  await nextTick()
  elVirtualScrollRef.value?.scrollTo(0, 'end')
}

// 行を削除

function onDelete(index: number) {
  data.value.splice(index, 1)
}

// バリデーション

const validations = {
  name: (v?: string|null) => v != null && 0 < v.length,
}

function validateAll() {
  for(const [index, row] of data.value.entries()) {
    for(const [key, validation] of Object.entries(validations)) {
      if(!validation((row as any)[key])) {
        elVirtualScrollRef.value?.scrollTo(index, 'center')
        return false
      }
    }
  }
  return true
}

// 税のコントロール

const optionInTax = [
  {value: true,  label: '内税'},
  {value: false, label: '外税'},
]

// 繰越のコントロール

const optionInCarry = [
  {value: false, label: 'なし'},
  {value: true,  label: 'あり'},
]
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
body.body--dark .client-offsets__header
  background-color: var(--q-dark-page)

  .client-offsets__text
    color: white

body.body--dark .sortable-drag
  background-color: var(--q-dark-page)

.client-offsets__drag-handle
  align-self: stretch
  height: auto !important

.client-offsets__col-intax,
.client-offsets__col-carry
  max-width: 120px !important
</style>
