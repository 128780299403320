
<!-- PaymentStatement 支払予定合計表 [一覧] -->

<template lang="pug">
component(:is="component")
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {computed} from 'vue'

import paymentStatementsList from './paymentStatementsList.vue'
import paymentStatementsListProducer from './paymentStatementsListProducer.vue'

import {useAuth} from '../plugins/Auth'
const auth = useAuth()

const component = computed(() =>
  auth.hasRoles('admin', 'client')
    ? paymentStatementsList
    : paymentStatementsListProducer
)
</script>
