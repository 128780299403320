
// TargetClient 対象団体

import {Type, Static, StringLiteralArray, DateTimeS, partialExcept, ApiSchema, StaticApi} from '@/aax/libs/typebox'

import {mm_client} from '../models/mm_client'

// API: 一括取得

export namespace TargetClientList {

  export const RowS = Type.Object({

    id:                     Type.Integer(),   // Int
    enable:   Type.Optional(Type.Boolean()),  // Boolean @default(false)
    created:  Type.Optional(DateTimeS),       // DateTime  登録日
    modified: Type.Optional(DateTimeS),       // DateTime  更新日

    mm_client,                                //
  })

  export const ReplyS = Type.Array(RowS)

  export const S = ApiSchema({
    response:    {'2xx': ReplyS},
  })

  export type T = StaticApi<typeof S>
}

// API: 一括更新

export namespace TargetClientUpdate {

  export const Require = StringLiteralArray(['id', 'enable'])

  export const RowS = partialExcept(TargetClientList.RowS, Require)
  export type RowT = Static<typeof RowS>

  export const BodyS = Type.Array(RowS)

  export const S = ApiSchema({
    body:        BodyS,
  })

  export type T = StaticApi<typeof S>
}

// API: 選択

export namespace TargetClientSelect {

  export const RowS = Type.Object({
    id:   Type.Integer(),  // Int
    name: Type.String(),  // String?
  })
  export type RowT = Static<typeof RowS>

  export const ReplyS = Type.Array(RowS)

  export const S = ApiSchema({
    response:    {'2xx': ReplyS},
  })

  export type T = StaticApi<typeof S>
}
