
<!-- PaymentData 支払情報 [一覧] -->

<template lang="pug">
//- 設定
x-list(
  :columns          = "columns"
  api-url           = "/_/api/payment-datas"
  api-count-url     = "/_/api/payment-datas/@count"
  row-key           = "id"
  :rows-per-load    = "100"
  selection         = "single"
  :additional-query = "additionalQuery"
  :menus            = "[{action: 'update', label: '開く', icon: 'article'}]"
  @row-update       = 'onRowUpdate'
)
  template(#header-right)
    x-target-client(v-model="searchClientId" dense)
    x-year-month(v-model="searchYearMonth" dense)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed} from 'vue'

import {format, parseISO, isValid} from 'date-fns'

import {getStringQuery, getIntQuery} from '@/aax/libs/vue-router'
import type {ColumnsT} from '@/aax/components-quasar/AqLazyTable.vue'

import {List} from '@/types/apis/PaymentStatements'

// injects

import {useRoute} from 'vue-router'
import { isString } from 'lodash-es'

const route = useRoute()

// カラムUI定義
const columns: ColumnsT = [
  {
    label: '支払データ作成日',
    name: 'processed',
    sortable: true,
    field: row => formatDate(row['processed']),
  },
  {
    label: '団体ID',
    name: 'clientId',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },  // Int
  {
    label: '団体名',
    name: 'clientName',
  },  // Int
  {
    label: '支払金額',
    name: 'amount',
    headerClasses: 'q-table--col-auto-width',
    field: row => row['amount'] ? row['amount']?.toLocaleString() : '',
  },  // Int?
]

function formatDate(str: string) {
  const obj = parseISO(str)
  return !isValid(obj) ? '' : format(obj, 'yyyy-MM-dd')
}

// 絞り込み
const searchYearMonth = ref(getStringQuery(route.query.month))
const searchClientId = ref(getIntQuery(route.query.client_id))

const additionalQuery = computed(() => {
  return {
    month: searchYearMonth.value,
    client_id: searchClientId.value,
  }
})

//
function onRowUpdate(row: List.RowT) {
  const dateObj = new Date(row.processed as any)
  if(!isValid(dateObj)) throw new Error('ファイルがみつかりません')

  const clientId = row.clientId
  const clientName = row.clientName
  const filepath = `${clientId}/${format(dateObj, 'yyyy/MM')}`

  const dateStr = isValid(dateObj) ? format(dateObj, 'yyyy_MM') : ''
  const filename = `支払いデータ_${clientId}_${dateStr}`

  window.open(`/_/payment-datas/${filepath}/${filename}.txt`, '_blank')
}

</script>
