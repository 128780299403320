<template lang="pug">
q-menu.non-selectable(
  v-bind="$attrs"
  @contextmenu.prevent
)
  q-list(dense)
    template(
      v-for="menu of finalMenus"
      :key="menu.label"
    )
      hr(v-if="menu.hr")
      x-menu-item(
        v-else
        :menu="menu"
        :label="menu.label"
        :icon="menu.icon"
        :disable="menu.disable"
        :hide="menu.hide"
        @click="menu.on?.()"
        @contextmenu="menu.on?.()"
      )
    slot
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {computed} from 'vue'
import {QMenu} from 'quasar'
import XMenuItem from './XMenuItem.vue'

export type MenuT = {
  label?:   string,
  icon?:    string,
  disable?: boolean,
  hide?:    boolean,
  hr?:      boolean,
  on?:      () => void,
}

// props
const props = defineProps<{
  menus: MenuT[],
}>()

// state
const finalMenus = computed(() => {
  const menus = props.menus.filter(menu => !menu.hide)
  if (menus[0]?.hr) {
    menus.shift()
  }
  if (menus[menus.length - 1]?.hr) {
    menus.pop()
  }
  return 0 < menus.length ? menus
    : [{label: '操作なし', disable: true}]
})
</script>
