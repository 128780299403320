
<!-- Bank 銀行 [詳細] -->

<template lang="pug">
//- 設定
x-form(
  v-model="data"
  api-url="/_/api/banks/"
  back-to="banksList"
  :readonly="Crud.Readonly"
)
  .q-pa-md.q-gutter-md

    //- カラムUI
    x-field-text(
      v-model="data.code"
      autofocus
      label="金融機関コード"
      mask="####"
      :rules="rules.code"
    )

    x-field-text(
      v-model="data.name"
      label="名称"
      :rules="rules.name"
    )

    x-field-text-zengin(
      v-model="data.kana"
      label="カナ"
      :rules="rules.kana"
    )

</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref} from 'vue'
import {isString} from 'lodash-es'
import {validateZengin} from '@/libs/zengin'

import {Crud} from '@/types/apis/Banks'

// カラム初期値
const data = ref<Crud.WriteT>({
  code: '',  // String?
  name: '',  // String?
  kana: '',  // String?
})

// バリデーション
const rules = {
  code: [(v: any) => (isString(v) && 0 < v.length) || '入力してください'],
  name: [(v: any) => (isString(v) && 0 < v.length) || '入力してください'],

  kana: [
    (v: any) => (isString(v) && 0 < v.length) || '入力してください',
    (v: any) => validateZengin(v) || '使用できるのは半角の数字、英大文字、カナのみです',
  ],
}
</script>
