<template lang="pug">
aq-page(fixed)
  .fit.overflow-auto.q-pa-lg(:class="{'no-pointer-events': noEvents}")
    .column.justify-center.items-center.q-gutter-lg

      x-group(label="入力")
        q-btn.btn(
          v-if="visible.clientOffsetValues"
          color="primary"
          @click="goto('clientOffsetValues')"
        ) 相殺金額

        q-btn.btn(
          v-if="visible.clientPaymentAdjustments"
          color="primary"
          @click="goto('clientPaymentAdjustments')"
        ) 団体調整項目

      x-group(label="参照")
        q-btn.btn(
          v-if="visible.paymentStatementsList"
          color="secondary"
          @click="gotoPaymentStatementsList"
        ) 生産者支払予定合計表

        q-btn.btn(
          v-if="visible.clientPaymentStatementsList"
          color="secondary"
          @click="gotoClientPaymentStatementsList"
        ) 団体支払明細

        //- routeがないため、adminのみを指すclientPaymentInfosListで代用
        q-btn.btn(
          v-if="visible.postPaymentStatements"
          color="secondary"
          @click="openPostPaymentStatements"
        ) 郵送用明細一括

        q-btn.btn(
          v-if="visible.paymentDatasList"
          color="secondary"
          @click="goto('paymentDatasList')"
        ) 支払データ

        q-btn.btn(
          v-if="visible.uncarriedShortageList"
          color="secondary"
          @click="goto('uncarriedShortageList')"
        ) 繰越されない未払い項目

      x-group(label="設定")
        q-btn.btn(
          v-if="visible.paymentInfosList"
          color="orange-10"
          @click="goto('paymentInfosList')"
        ) 生産者支払情報

        q-btn.btn(
          v-if="visible.clientPaymentInfosList"
          color="orange-10"
          @click="goto('clientPaymentInfosList')"
        ) 団体支払情報

        q-btn.btn(
          v-if="visible.targetClient"
          color="orange-10"
          @click="goto('targetClient')"
        ) 対象団体

        q-btn.btn(
          v-if="visible.banksList"
          color="orange-10"
          @click="goto('banksList')"
        ) 銀行

        q-btn.btn(
          v-if="visible.clientOffsets"
          color="orange-10"
          @click="goto('clientOffsets')"
        ) 相殺項目

      x-group
        q-btn.btn(
          v-if="visible.commands"
          color="negative"
          @click="goto('commands')"
        ) 管理者操作

        q-btn.btn(
          color="negative"
          @click="logout"
        ) ログアウト

        template(v-if="isDevelopment")
          q-btn.btn(
            color="purple"
            @click="router.push({name: 'userInfo'})"
          ) ログイン情報

</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed} from 'vue'
import {useRouter} from 'vue-router'
import {isString} from 'lodash-es'

import { openPostPaymentStatements } from './postPaymentStatements'

import {useAuth} from '../plugins/Auth'
import type {Role} from '@/types/plugins/Auth'

import {useDialog} from '@/aax/plugins/Dialog'
import {useAxios} from '@/aax/plugins/Axios'


const auth = useAuth()
const router = useRouter()
const dialog = useDialog()
const axios = useAxios().$scoped()

const visible = computed(() => {
  const visible: {[x: string]: boolean} = {}
  for(const route of router.options.routes) {
    if(isString(route.name)) {
      const roles = route.meta?.roles as Role[]|undefined
      visible[route.name] = roles == null || auth.hasRoles(...roles)
    }
  }

  // ダウンロードのみのrouteのvisible
  visible['postPaymentStatements'] = auth.hasRoles('admin')

  return visible
})

async function goto(name: string) {
  await router.push({name})
}

// 支払明細は明細が存在する最新の年月に飛ぶように
const noEvents = ref(false)

async function gotoPaymentStatementsList() {
  noEvents.value = true
  const month = (await axios.$get('/_/api/payment-statements/@latest')).toString()
  await router.push({
    name: 'paymentStatementsList',
    query: {month},
  })
}

async function gotoClientPaymentStatementsList() {
  noEvents.value = true
  const month = (await axios.$get('/_/api/client-payment-statements/@latest')).toString()
  await router.push({
    name: 'clientPaymentStatementsList',
    query: {month},
  })
}

//
async function logout() {
  await dialog.confirm({
    title: 'ログアウト',
    message: 'ログアウトします。よろしいですか？',
  })

  await auth.logout()
}

const isDevelopment = ref(process.env.NODE_ENV === 'development')
</script>

<!----------------------------------------------------------------------------->

<style scoped lang="sass">
.btn
  width: 400px
</style>
