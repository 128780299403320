
<!-- ClientPaymentAdjustment 団体調整項目 [一覧] -->

<template lang="pug">
aq-page(fixed :loading="loading")
  template(#header-right)
    x-year-month(v-model="searchYearMonth" dense no-clearable :add-month="-1")

    q-btn(
      label="保存"
      :color="notTouch ? 'grey' : 'primary'"
      :disable="notTouch"
      @click="onSave"
    )

  q-virtual-scroll(
    ref="elVirtualScrollRef"
    style="max-height: 100%"
    :items="data"
    :virtual-scroll-item-size="44"
    :virtual-scroll-sticky-size-start="46"
  )
    template(#before)
      div(:class="headerClass")
        q-item.items-center.q-pa-xs(dense)
          x-col-header.col.client-payment-adjustments__col-value.q-mr-xs 団体ID
          x-col-header.col.q-mr-xs 団体名
          x-col-header.col.client-payment-adjustments__col-value.q-mr-xs 計算上の差分
          x-col-header.col.client-payment-adjustments__col-value.q-mr-xs 振込手数料

    template(#default="{index, item}")
      q-item.items-center.q-px-xs(
        dense
        :key="item.clientId"
      )
        x-col-header.col.client-payment-adjustments__col-value.q-mr-xs {{item.clientId}}
        x-col-header.col.q-mr-xs {{item.clientName}}

        x-col-text.col.client-payment-adjustments__col-value.q-mr-xs(
          v-model="item.adjustment"
          :disable="disable"
          :rules="[validations.adjustment]"
          number input-class="text-right"
        )

        x-col-text.col.client-payment-adjustments__col-value.q-mr-xs(
          v-model="item.transferFee"
          :disable="disable"
          :rules="[validations.transferFee]"
          number input-class="text-right"
        )
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, watch, onBeforeMount} from 'vue'
import {isBefore, isValid, parse, startOfMonth, subDays} from 'date-fns'
import {cloneDeep, isEqual} from 'lodash-es'
import {QVirtualScroll} from 'quasar'

import {getStringQuery} from '@/aax/libs/vue-router'
import {Put} from '@/types/apis/ClientPaymentAdjustmentsView'

import {leaveGuard} from '@/aax/libs/leaveGuard'

// injects

import {useRouter} from 'vue-router'
import {useQuasar} from 'quasar'
import {useAxios, testAxiosError} from '@/aax/plugins/Axios'
import {useDialog} from '@/aax/plugins/Dialog'

// バリデーション

import {makeMonthRangeTest} from '@/types/libs/makeMonthRange'

// injects

const router = useRouter()
const $q = useQuasar()
const axios = useAxios().$scoped()
const dialog = useDialog()

// ダークモード対応

const headerClass = computed(() => 'client-offset-values__header--' + ($q.dark.isActive ? 'dark' : 'light'))

// 前月以前は編集不可

const searchYearMonth = ref(getStringQuery(router.currentRoute.value.query.month))

const currentDate = ref(startOfMonth(subDays(new Date(), 9))) // 10日0時以前は前月を扱う

const disable = computed(() => {
  if(searchYearMonth.value == null) return true
  const minimumDate = parse(searchYearMonth.value, 'yyyyMM', 0)
  return !isValid(minimumDate) || isBefore(minimumDate, currentDate.value)
})

// 絞り込み

watch([searchYearMonth], async () => {
  await router.replace({query: {
    month: searchYearMonth.value ?? undefined,
  }})
})

// データ

const data = ref<Put.RowT[]>([])
const loading = ref(false)

const original = ref<Put.RowT[]>([])
const notTouch = computed(() => disable.value || isEqual(data.value, original.value))

let lastUrl: string|undefined

function getApiUrl() {
  return searchYearMonth.value == null ? undefined
    : '/_/api/client-payment-adjustments/@view/' + searchYearMonth.value
}

async function loadData(force: boolean = false) {
  const url = getApiUrl()
  if(url != null && (force || url !== lastUrl)) {
    lastUrl = url
    try {
      loading.value = true
      data.value = []
      original.value = []
      data.value = await axios.$get(url)
      original.value = cloneDeep(data.value)
    }
    finally {
      loading.value = false
    }
  }
}

onBeforeMount(async () => {
  await Promise.all([
    loadData(),
  ])
})

watch([searchYearMonth], async () => {
  await loadData()
})

// 保存

async function onSave() {
  if(!validateAll()) {
    await dialog.alert({message: 'シートを正しく記入してください'})
    return
  }

  const url = getApiUrl()
  if(url != null) {
    try {
      loading.value = true
      await axios.$put(url, data.value)
    }
    catch(e) {
      if(!testAxiosError(e, [400, 404])) throw e
      await dialog.alert({message: 'このシートの更新は締め切られています'})
      return
    }
    finally {
      loading.value = false
    }
  }
  await loadData(true)
}

// バリデーション

const elVirtualScrollRef = ref<QVirtualScroll|null>(null)

const validations = {
  adjustment:  (v?: number|null) => v != null,
  transferFee: (v?: number|null) => v != null,
}

function validateAll() {
  for(const [index, row] of data.value.entries()) {
    for(const [key, validation] of Object.entries(validations)) {
      if(!validation((row as any)[key])) {
        elVirtualScrollRef.value?.scrollTo(index, 'center')
        return false
      }
    }
  }
  return true
}

// 編集破棄ガード

leaveGuard(() => !notTouch.value)

</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.client-payment-adjustments__col-value
  max-width: 120px !important
</style>
