
// エラーレポート (基底)

import {App, InjectionKey} from 'vue'

import {pluginInject, pluginInjectApp} from '../libs/pluginInject'

// 依存プラグイン
import {ToastKey} from './Toast'

//--------------------------------------------------------------

// (型) 依存構成
export type ReportDepends = ReturnType<typeof getReportDepends>

// (型) オプション
export type ReportOptions = {}

// (型) インスタンス
export type ReportObject = ReturnType<typeof createReport>

//--------------------------------------------------------------

// (内部) インジェクションキー
export const ReportKey: InjectionKey<ReportObject> = Symbol('report')

// (内部) 依存構成
export function getReportDepends(app: App) {
  return {
    toast: pluginInjectApp(app, ToastKey),
  }
}

// (内部) インスタンス作成
export function createReport(depends: ReportDepends, options?: ReportOptions) {
  const {toast} = depends

  return {
    async submit(severity: string, ...args: any[]) {
      toast.show(severity, ...args)
    },
  }
}

//--------------------------------------------------------------

// プラグインインストール
export const ReportPlugin = {
  install(app: App, options?: ReportOptions) {
    app.provide(ReportKey, createReport(getReportDepends(app), options))
  },
}

// プラグイン取得
export function useReport() {
  return pluginInject(ReportKey)
}
