<template lang="pug">
slot(v-if="state")
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, watch, onBeforeMount} from 'vue'

import {useAuth} from '../plugins/Auth'

const auth = useAuth()

const state = ref(false)

onBeforeMount(async () => {
  if(auth.auth.value === undefined) {
    await auth.login()
  }
  state.value = true
})

watch(auth.auth, async value => {
  if(value === undefined) {
    await auth.login()
  }
})
</script>
