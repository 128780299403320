
<!-- aggregate 日付・項目別集計表 -->

<template lang="pug">
aq-page(fixed :loading="loading")
  template(#header-left)
    q-btn(
      dense flat round
      icon="close"
      @click="onClose"
    )

  template(#header-title)
    .aq-page-x-title.non-selectable 日付・項目別集計表

  q-table.aggregate-table.full-height(
    dense square virtual-scroll
    separator="cell"
    :rows-per-page-options="[0]"
    :columns="columns"
    :rows="data"
  )
    template(#pagination)
      div 全{{data.length}}行

</template>

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'
import type {QTableProps} from 'quasar'

import {format, parseISO, isValid} from 'date-fns'

import {getIntQuery, getStringQuery} from '@/aax/libs/vue-router'
import {Get}                         from '@/types/apis/AggregateView'

// injects

import {useRouter} from 'vue-router'
import {useAxios} from '@/aax/plugins/Axios'

const router = useRouter()
const axios = useAxios().$scoped()

// カラムUI定義
const columns: QTableProps['columns'] = [
  {
    label: '相殺日',
    name: 'processed',
    field: row => formatDate(row['processed']),
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },  // DateTime?

  {
    label: '相殺項目',
    name: 'clientOffsetName',
    field: 'clientOffsetName',
    sortable: true,
    align: 'left',
    headerClasses: 'q-table--col-auto-width',
  },  // String?

  {
    label: '金額',
    name: 'value',
    field: 'value',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },  // Int

  {
    label: '',
    name: 'space',
    field: () => '',
  },
]

function formatDate(str: string) {
  const obj = parseISO(str)
  return !isValid(obj) ? '' : format(obj, 'yyyy-MM-dd')
}

// 絞り込み

const searchYearMonth = ref(getStringQuery(router.currentRoute.value.query.month))
const searchClientId = ref(getIntQuery(router.currentRoute.value.query.client_id))

// データ

const data = ref<Get.RowT[]>([])
const loading = ref(false)

function getApiUrl() {
  return (searchYearMonth.value == null || searchClientId.value == null) ? undefined
    : '/_/api/aggregate/@view/' + searchYearMonth.value + '/' + searchClientId.value
}

async function loadData() {
  const url = getApiUrl()
  if(url != null) {
    try {
      loading.value = true
      data.value = []
      data.value = await axios.$get(url)
    }
    catch(e: any) {
      if(e.response?.status !== 404) {
        throw e
      }
    }
    finally {
      loading.value = false
    }
  }
}

onBeforeMount(async () => {
  await Promise.all([
    loadData(),
  ])
})

// 閉じる

async function onClose() {
  window.close()
}
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
$table-header-light: darken(white, 7)   !default
$table-header-dark:  lighten($dark, 15) !default

.aggregate-table
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: $table-header-light

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  .q-virtual-scroll__content
    user-select: none

  &.q-dark
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th
      background-color: $table-header-dark
</style>
