
import {createRouter, createWebHistory} from 'vue-router'

import menu                        from './views/menu.vue'

// 銀行
import banksList                   from './views/banksList.vue'
import banksDetail                 from './views/banksDetail.vue'

// 相殺項目
import clientOffsets               from './views/clientOffsets.vue'

// 日付・項目別集計表 (相殺項目から遷移)
import aggregate                   from './views/aggregate.vue'

// 相殺金額
import clientOffsetValues          from './views/clientOffsetValues.vue'

// 団体調整項目
import clientPaymentAdjustments    from './views/clientPaymentAdjustments.vue'

// 団体支払情報
import clientPaymentInfosList      from './views/clientPaymentInfosList.vue'
import clientPaymentInfosDetail    from './views/clientPaymentInfosDetail.vue'

// 支払情報
import paymentInfosList            from './views/paymentInfosList.vue'
import paymentInfosDetail          from './views/paymentInfosDetail.vue'

// 支払明細
import paymentStatementsList       from './views/paymentStatementsListProxy.vue'

// 団体支払明細
import clientPaymentStatementsList from './views/clientPaymentStatementsList.vue'

// 支払情報
import paymentDatasList            from './views/paymentDatasList.vue'

// 繰越されない未払い項目
import uncarriedShortageList       from './views/uncarriedShortageList.vue'

// 対象団体
import targetClient                from './views/targetClient.vue'

// 管理者コマンド
import commands                    from './views/commands.vue'

//
import userInfo                    from './views/userInfo.vue'

import notFound                    from './views/notFound.vue'

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: '/',
      name: 'menu',
      component: menu,
      meta: {title: 'メニュー', icon: 'apps'},
    },


    // 銀行
    {
      path: '/banks',
      name: 'banksList',
      component: banksList,
      meta: {title: '銀行', icon: 'account_balance', roles: ['admin']},
    },
    {
      path: '/banks/:id',
      name: 'banksDetail',
      component: banksDetail,
      meta: {parent: 'banksList'},
      props: true,
    },

    // 相殺項目
    {
      path: '/client-offsets',
      name: 'clientOffsets',
      component: clientOffsets,
      meta: {title: '相殺項目', icon: 'style', roles: ['admin']},
    },

    // 相殺金額
    {
      path: '/client-offset-values',
      name: 'clientOffsetValues',
      component: clientOffsetValues,
      //meta: {title: '相殺金額', icon: 'balance', roles: ['admin', 'client']},
      meta: {title: '相殺金額', icon: 'balance', roles: ['admin']},
    },

    // 団体調整項目
    {
      path: '/client-payment-adjustments',
      name: 'clientPaymentAdjustments',
      component: clientPaymentAdjustments,
      meta: {title: '団体調整項目', icon: 'balance', roles: ['admin']},
    },

    // 日付・項目別集計表 (相殺項目から遷移)
    {
      path: '/aggregate',
      name: 'aggregate',
      component: aggregate,
      meta: {title: '日付・項目別集計表', icon: 'balance', roles: ['admin', 'client']},
    },

    // 支払情報
    {
      path: '/payment-infos',
      name: 'paymentInfosList',
      component: paymentInfosList,
      meta: {title: '生産者支払情報', icon: 'account_box', roles: ['admin']},
    },
    {
      path: '/payment-infos/:id',
      name: 'paymentInfosDetail',
      component: paymentInfosDetail,
      meta: {parent: 'paymentInfosList'},
      props: true,
    },

    // 団体支払情報
    {
      path: '/client-payment-infos',
      name: 'clientPaymentInfosList',
      component: clientPaymentInfosList,
      meta: {title: '団体支払情報', icon: 'account_box', roles: ['admin']},
    },
    {
      path: '/client-payment-infos/:id',
      name: 'clientPaymentInfosDetail',
      component: clientPaymentInfosDetail,
      meta: {parent: 'clientPaymentInfosList'},
      props: true,
    },

    // 支払明細
    {
      path: '/payment-statements',
      name: 'paymentStatementsList',
      component: paymentStatementsList,
      meta: {title: '生産者支払予定合計表', icon: 'paid', roles: ['admin', 'client', 'producer']},
    },

    // 団体支払明細
    {
      path: '/client-payment-statements',
      name: 'clientPaymentStatementsList',
      component: clientPaymentStatementsList,
      //meta: {title: '団体支払明細', icon: 'paid', roles: ['admin', 'client']},
      meta: {title: '団体支払明細', icon: 'paid', roles: ['admin']},
    },

    // 支払データ
    {
      path: '/payment-datas',
      name: 'paymentDatasList',
      component: paymentDatasList,
      meta: {title: '支払データ', icon: 'paid', roles: ['admin']},
    },

    // 繰越されない未払い項目
    {
      path: '/uncarried-shortages',
      name: 'uncarriedShortageList',
      component: uncarriedShortageList,
      meta: {title: '繰越されない未払い項目', icon: 'paid', roles: ['admin', 'client']},
      //meta: {title: '繰越されない未払い項目', icon: 'paid', roles: ['admin']},
    },

    // 対象団体
    {
      path: '/target-clients',
      name: 'targetClient',
      component: targetClient,
      meta: {title: '対象団体', icon: 'groups', roles: ['admin']},
    },

    // 管理者操作
    {
      path: '/commands',
      name: 'commands',
      component: commands,
      meta: {title: '管理者操作', icon: 'groups', roles: ['admin']},
    },

    // テスト

    {
      path: '/userinfo',
      name: 'userInfo',
      component: userInfo,
      meta: {roles: ['admin']},
    },

    //

    { // 404エラーページ
      path: '/:catchAll(.*)',
      component: notFound,
    },
  ],
})
