<template lang="pug">
q-item.non-selectable(
  clickable v-ripple
  :active="active"
  @click="click"
)
  q-item-section(avatar)
    q-icon(:name="icon")
  q-item-section {{title}}
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
const props = withDefaults(defineProps<{
  icon?: string,
  title?: string,
  active?: boolean,
}>(), {
  active: false,
})

const emit = defineEmits<{
  (e: 'click', ev: MouseEvent): void,
}>()

function click(ev: Event) {
  emit('click', ev as MouseEvent)
}
</script>
