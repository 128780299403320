
// テーブル形式フォームのカラム入力: 生産者選択

import {ref, computed, provide, inject, InjectionKey} from 'vue'
import {useAxios} from '@/aax/plugins/Axios'
import {Common, ProducerSearch} from '@/types/apis/Producers'

// オプション
type Options = {
  clientId?: number|null,
  initLoad?: boolean,
}

// インスタンス
type Object = ReturnType<typeof createInstance>

// インジェクションキー
const Key: InjectionKey<Object> = Symbol('x-producer-data')

// インスタンス作成
function createInstance(options: Options = {}) {

  const axios = useAxios()

  const rawData = ref<Common.RowT[]|undefined>(undefined)

  const data = computed(() => rawData.value?.map(row => ({
    value: row.producerId,
    label: row.producerId + ' ' + row.producerName,
    ...row,
  })))

  const obj = {

    get data() {
      return data
    },

    async load(clientId?: number|null) {
      rawData.value = undefined
      rawData.value = await axios.$get('/_/api/producers/@select', {params: {client_id: clientId}})
    },

    async search(producerId: number) {
      const producers = await axios.$get<(ProducerSearch.RowT|undefined)[]>('/_/api/producers/@search/' + producerId)
      return producers[0]
    },
  }

  if(options.initLoad !== false) {
    obj.load(options.clientId)
  }

  return obj
}

// インスタンス登録
export function provideProducerData(options?: Options) {
  const obj = createInstance(options)
  provide(Key, obj)
  return obj
}

// インスタンス取得
export function useProducerData(options?: Options) {
  return inject(Key) ?? provideProducerData(options)
}
