<template lang="pug">
aq-page
  .q-pa-md
    q-markup-table(bordered dense separator="cell")
      tbody
        tr(v-for="(value, name) of auth")
          td.text-right {{name}}
          td {{value}}
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {useAuth} from '@/aax/plugins/Auth'

const {auth} = useAuth()
</script>
