<template lang="pug">
div
  q-btn-dropdown(
    color="primary"
    label="表示"
  )
    q-list
      x-menu-item(
        label="支払金額0を非表示"
        :icon="modelValue ? 'check_box' : 'check_box_outline_blank'"
        @click="onToggleWithoutZero"
      )
</template>

<script setup lang="ts">
import XMenuItem from './XMenuItem.vue'

const props = defineProps<{
  modelValue?: number|null,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number|null): void,
}>()

function onToggleWithoutZero() {
  emit('update:modelValue', props.modelValue ? null : 1)
}
</script>
