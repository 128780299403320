<template lang="pug">
q-input(
  ref="elInput"
  v-bind="$attrs"
  filled
  :modelValue="modelValue"
  @update:modelValue="onUpdate"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, nextTick} from 'vue'
import {convertZengin} from '@/libs/zengin'

const props = defineProps<{
  modelValue: string|number|null,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|number|null): void,
}>()

const elInput = ref<any>(null)

async function onUpdate(value: string|number|null) {
  const text = value?.toString() ?? ''

  const el = elInput.value?.$el.querySelector('input')
  const pos = text.length - el.selectionEnd

  const newText = convertZengin(text)
  const newPos = newText.length - pos

  emit('update:modelValue', newText)

  await nextTick()
  el.setSelectionRange(newPos, newPos)
}
</script>
