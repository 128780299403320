
<!-- PaymentInfo 支払情報 [詳細] -->

<template lang="pug">
//- 設定
x-form(
  v-model="data"
  api-url="/_/api/payment-infos/"
  back-to="paymentInfosList"
  :readonly="Update.Readonly"
)
  .q-pa-md.q-gutter-md

    //- カラムUI

    x-field-text(
      :modelValue="data.mm_producer?.name"
      label="代表生産者"
      readonly
    )

    x-field-text(
      :modelValue="data.mm_client?.name"
      label="団体名"
      readonly
    )

    x-field-rel(
      v-model="data.bankId"
      label="銀行"

      api-url="/_/api/banks/$"
      row-key="id"
      label-key="name"
      search-key="name"
      :rows-per-load="100"

      :rules="rules.bankId"
    )

    x-field-text(
      v-model="data.no"
      label="口座番号"
      mask="########"
      :rules="rules.no"
    )

    x-field-text(
      v-model="data.branchNo"
      label="支店番号"
      mask="###"
      :rules="rules.branchNo"
    )

    x-field-text-zengin(
      v-model="data.holder"
      label="口座名義"
      :rules="rules.holder"
    )

    //- x-field-map(
    //-   v-model="data.send"
    //-   :options="FieldSend"
    //- )

    x-field-text(
      v-model="data.name"
      label="送付先 宛先名"
      :rules="rules.name"
    )
      //- :disable="!data.send"

    x-field-text(
      v-model="data.zip"
      label="送付先 郵便番号"
      mask="###-####"
      :rules="rules.zip"
    )
      //- :disable="!data.send"

    x-field-text(
      v-model="data.address"
      label="送付先 住所"
      :rules="rules.address"
    )
      //- :disable="!data.send"
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref} from 'vue'
import {isString} from 'lodash-es'
import {validateZengin} from '@/libs/zengin'

import {Update, FieldSend} from '@/types/apis/PaymentInfos'

// カラム初期値
const data = ref<Update.RowT>({
  bankId:   '',    // String
  no:       '',    // String?
  branchNo: '',    // String?
  holder:   '',    // String?

  send:    false,  // Boolean?
  name:    '',     // String?
  zip:     '',     // String?
  address: '',     // String?
})

// バリデーション
const rules = {
  bankId:   [(v: any) => (isString(v) && 0 < v.length) || '入力してください'],
  no:       [(v: any) => (isString(v) && 0 < v.length) || '入力してください'],
  branchNo: [(v: any) => (isString(v) && 0 < v.length) || '入力してください'],

  holder: [
    (v: any) => (isString(v) && 0 < v.length) || '入力してください',
    (v: any) => validateZengin(v) || '使用できるのは半角の数字、英大文字、カナのみです',
  ],

  name:     [(v: any) => true /*(isString(v) && 0 < v.length) || '入力してください'*/],
  zip:      [(v: any) => true /*(isString(v) && 0 < v.length) || '入力してください'*/],
  address:  [(v: any) => true /*(isString(v) && 0 < v.length) || '入力してください'*/],
}
</script>
