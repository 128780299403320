
// PaymentInfo 支払情報

import {Type, Static, StringLiteralArray, DateTimeS, partialOf, ApiSchema, StaticApi} from '@/aax/libs/typebox'

import * as TableOps from '@/aax/libs/prismaTableOpsTypes'

import {mm_producer} from '../models/mm_producer'
import {mm_client}   from '../models/mm_client'

// 共通

export namespace Common {

  export const RowS = Type.Object({

    id:                     Type.Integer(),   // Int

    bankId:                 Type.String(),    // String              銀行ID
    no:       Type.Optional(Type.String()),   // String @default("") 口座番号
    branchNo: Type.Optional(Type.String()),   // String @default("") 支店番号
    holder:   Type.Optional(Type.String()),   // String @default("") 口座名義

    send:     Type.Optional(Type.Boolean()),  // Boolean @default(false) 送付するかどうか
    name:     Type.Optional(Type.String()),   // String @default("")     宛先名
    zip:      Type.Optional(Type.String()),   // String @default("")     郵便番号
    address:  Type.Optional(Type.String()),   // String @default("")     住所

    created:  Type.Optional(DateTimeS),       // DateTime 登録日
    modified: Type.Optional(DateTimeS),       // DateTime 更新日

    mm_producer: Type.Optional(mm_producer),  //
    mm_client:   Type.Optional(mm_client),    //
  })

  export const QuerystringS = Type.Intersect([TableOps.S, Type.Object({
    client_id: Type.Optional(Type.Integer()),
  })])
}

// API: 件数

export namespace Count {

  export const S = ApiSchema({
    querystring: Common.QuerystringS,
    response:    {'2xx': Type.Integer()},
  })
  export type T = StaticApi<typeof S>
}

// API: 一覧

export namespace List {

  export const Optional = StringLiteralArray([
    'bankId', 'no', 'branchNo', 'holder',
    'send', 'name', 'zip', 'address',
  ])

  export const RowS = partialOf(Common.RowS, Optional)

  //

  export const S = ApiSchema({
    querystring: Common.QuerystringS,
    response:    {'2xx': Type.Array(RowS)},
  })
  export type T = StaticApi<typeof S>
}

// API: 取得

export namespace Read {

  export const RowS = List.RowS

  //

  export const S = ApiSchema({
    params:      Type.Object({id: Type.Integer()}),
    response:    {'2xx': RowS},
  })
  export type T = StaticApi<typeof S>
}

// API: 更新

export namespace Update {

  export const Readonly = StringLiteralArray(['id', 'created', 'modified', 'mm_producer', 'mm_client'])

  export const RowS = partialOf(Common.RowS, Readonly)
  export type RowT = Static<typeof RowS>

  //

  export const S = ApiSchema({
    params:      Type.Object({id: Type.Integer()}),
    body:        RowS,
  })
  export type T = StaticApi<typeof S>
}

// UI

export const FieldSend = [
  {value: false, label: '送付しない'},
  {value: true,  label: '送付する'},
]
