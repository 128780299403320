
import {App} from 'vue'

import AqPage             from './AqPage.vue'
import AqFilterInput      from '@/aax/components-quasar/AqFilterInput.vue'
import AqLazyTable        from '@/aax/components-quasar/AqLazyTable.vue'
import XBtn               from './XBtn.vue'
import XColCarry          from './XColCarry.vue'
import XColClientOffset   from './XColClientOffset.vue'
import XColDate           from './XColDate.vue'
import XColHeader         from './XColHeader.vue'
import XColProducer       from './XColProducer.vue'
import XColText           from './XColText.vue'
import XFieldClientOffset from './XFieldClientOffset.vue'
import XFieldText         from './XFieldText.vue'
import XFieldTextZengin   from './XFieldTextZengin.vue'
import XFieldDate         from './XFieldDate.vue'
import XFieldMap          from './XFieldMap.vue'
import XFieldRel          from './XFieldRel2.vue'
import XForm              from './XForm.vue'
import XGroup             from './XGroup.vue'
import XList              from './XList.vue'
import XMenu              from './XMenu.vue'
import XTargetClient      from './XTargetClient.vue'
import XYearMonth         from './XYearMonth2.vue'

export const ComponentsPlugin = {
  install(app: App) {
    app
    .component('AqPage',             AqPage)
    .component('AqFilterInput',      AqFilterInput)
    .component('AqLazyTable',        AqLazyTable)
    .component('XBtn',               XBtn)
    .component('XColCarry',          XColCarry)
    .component('XColClientOffset',   XColClientOffset)
    .component('XColDate',           XColDate)
    .component('XColHeader',         XColHeader)
    .component('XColProducer',       XColProducer)
    .component('XColText',           XColText)
    .component('XFieldClientOffset', XFieldClientOffset)
    .component('XFieldText',         XFieldText)
    .component('XFieldTextZengin',   XFieldTextZengin)
    .component('XFieldDate',         XFieldDate)
    .component('XFieldMap',          XFieldMap)
    .component('XFieldRel',          XFieldRel)
    .component('XForm',              XForm)
    .component('XGroup',             XGroup)
    .component('XList',              XList)
    .component('XMenu',              XMenu)
    .component('XTargetClient',      XTargetClient)
    .component('XYearMonth',         XYearMonth)
  },
}
