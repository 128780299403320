
import {App} from 'vue'

import {
  Quasar,

  // plugins
  Dark,
  Dialog,
  Notify,
} from 'quasar'

import quasarLang from 'quasar/lang/ja'

export const QuasarPlugin = {
  install(app: App) {

    app.use(Quasar, {
      lang: quasarLang,
      plugins: {
        Dialog,
        Notify,
      },
    })

    Dark.set('auto')
  },
}

import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
