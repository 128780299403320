<template lang="pug">
aq-page(fixed)
  template(#header-right)
    slot(name="header-right")
      slot(name="append-filter")

      aq-filter-input(
        v-model="searchText"
      )

    //- メニュー
    q-btn(
      dense flat round
      icon="more_vert"
    )
      x-menu(:menus="menus")
        slot(name="menu")

  x-menu(
    context-menu touch-position
    :menus="menus"
  )
    slot(name="menu")

  //- テーブル
  aq-lazy-table.full-height(
    ref="elTable"

    :columns="columns"

    :api-url="apiUrl"
    :api-count-url="apiCountUrl"
    :offset-method="offsetMethod"
    :row-key="rowKey"
    :rows-per-load="rowsPerLoad"

    selectable-row
    :selection="selection"
    v-model:selected="selected"
    @row-dblclick="onRowDblclick"

    v-model:search-text="searchText"
    :additional-query="additionalQuery"

    :eager="eager"
  )
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, watch} from 'vue'
import {isString} from 'lodash-es'

import AqLazyTable from '@/aax/components-quasar/AqLazyTable.vue'
import type {ColumnsT, SelectionT, SelectedT, OnRowDblclickT} from '@/aax/components-quasar/AqLazyTable.vue'
import type { MenuT } from './XMenu.vue'

export type LooseDictionary = {[x: string]: any}

// inject

import {useRouter} from 'vue-router'
import {useAxios} from '@/aax/plugins/Axios'
import {useDialog} from '@/aax/plugins/Dialog'

// type ActionType = keyof typeof actions // BUG: definePropsの型が正しく反映されない
export type ActionType = 'create'|'update'|'delete'
export type Action = ({action?: ActionType} & Partial<MenuT>) | ActionType

// inject

const router = useRouter()
const axios = useAxios().$scoped()
const dialog = useDialog()

// props
const props = withDefaults(defineProps<{
  columns:        ColumnsT,

  apiUrl:         string,
  apiCountUrl?:   string,
  offsetMethod?:  'cursor'|'offset',
  rowKey?:        string,
  rowsPerLoad?:   number,

  selection?:     SelectionT,
  selected?:      SelectedT,

  additionalQuery?: {[k: string]: string|number}|false,

  nextTo?:        string,
  dblclick?:      ActionType|false,
  menus?:         Action[],

  eager?:         boolean,
}>(), {
  offsetMethod:   'offset',
  rowKey:         'id',

  selection:      'multiple',
  selected:       () => [],

  additionalQuery: undefined,

  dblclick:       'update',
  menus:          () => ['create', 'update', 'delete'],
})

// emits
const emit = defineEmits<{
  (e: 'rowDblclick', evt: LooseDictionary, row: LooseDictionary, index: number): void,
  (e: 'rowUpdate', row: LooseDictionary): void,
  (e: 'update:selected', value: SelectedT): void,
}>()

// state
const elTable = ref<InstanceType<typeof AqLazyTable>>()

// 操作
const actions = {
  create: (menu?: Partial<MenuT>) => ({label: '新規作成', icon: 'add',    on: actionCreate,                                     ...menu}),
  update: (menu?: Partial<MenuT>) => ({label: '編集',     icon: 'edit',   on: actionUpdate, hide: selected.value?.length !== 1, ...menu}),
  delete: (menu?: Partial<MenuT>) => ({label: '削除',     icon: 'delete', on: actionDelete, hide: selected.value?.length === 0, ...menu}),
}

function getMenu(action: Action) {
  if(isString(action)) return actions[action]()
  const {action: actionType, ...menu} = action
  return actionType == null ? {label: '', ...menu} : actions[actionType](menu)
}

async function actionCreate() {
  if(props.nextTo) {
    await router.push({name: props.nextTo, params: {id: '@new'}})
  }
}

async function actionUpdate() {
  const row = selected.value?.[0]
  if(row != null) {
    if(props.nextTo) {
      const id = row[props.rowKey]
      if(id != null) await router.push({name: props.nextTo, params: {id}})
    }
    emit('rowUpdate', row)
  }
}

async function actionDelete() {
  await dialog.confirm({
    title: '削除の確認',
    message: '選択した項目を削除します。よろしいですか？',
  })

  if(selected.value != null) {
    const keys = selected.value.map(row => row[props.rowKey])
    await axios.$delete(props.apiUrl, {data: keys})
    await (elTable.value as any)?.reload() // TODO: <script setup>の制約によりメソッドをexportできない
  }
}

// 選択
const selected = ref<SelectedT>(props.selected)

watch(selected, (value) => {
  emit('update:selected', value)
})

// 検索
const searchText = ref<string|null>(null)

// メニュー
const menus = computed(() => props.menus.map(getMenu))

// ダブルクリック
const dblclick = computed(() => props.dblclick ? getMenu(props.dblclick) : null)

const onRowDblclick: OnRowDblclickT = async (evt, row, index) => {
  if(dblclick.value && !dblclick.value.disable && !dblclick.value.hide) {
    dblclick.value.on?.()
  }
  emit('rowDblclick', evt, row, index)
}
</script>
