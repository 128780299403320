
// Vueエラーハンドリング

import {App, InjectionKey} from 'vue'

import {pluginInject} from '../libs/pluginInject'

//--------------------------------------------------------------

// (型) 依存構成
export type ErrorHandlerDepends = ReturnType<typeof getErrorHandlerDepends>

// (型) オプション
export type ErrorHandlerOptions = {}

// (型) インスタンス
export type ErrorHandlerObject = ReturnType<typeof createErrorHandler>

//--------------------------------------------------------------

// (内部) インジェクションキー
export const ErrorHandlerKey: InjectionKey<ErrorHandlerObject> = Symbol('error-handler')

// (内部) 依存構成
export function getErrorHandlerDepends(app: App) {
  return {
    app,
  }
}

// (内部) インスタンス作成
export type ErrorHandler = (err: any, next: () => any) => any

export function createErrorHandler(depends: ErrorHandlerDepends, options?: ErrorHandlerOptions) {
  const {app} = depends

  const handlers = [] as ErrorHandler[]

  const obj = {

    addHandler(handler: ErrorHandler) {
      handlers.push(handler)
    },

    submit(err: any) {
      let result: any
      let entry: () => any = () => {}
      for(const handler of handlers) {
        let next = entry
        entry = () => {
          if(!result) {
            const r = handler(err, next)
            if(r) result = r
          }
          return result
        }
      }
      if(!entry()) {
        console.error(err)
      }
      else if(typeof result === 'function') {
        result()
      }
    },
  }

  app.config.errorHandler = (err, instance, info) => {
    obj.submit(err)
  }

  app.config.warnHandler = (msg, instance, trace) => {
    console.warn(msg)
  }

  return obj
}

//--------------------------------------------------------------

// プラグインインストール
export const ErrorHandlerPlugin = {
  install(app: App, options?: ErrorHandlerOptions) {
    app.provide(ErrorHandlerKey, createErrorHandler(getErrorHandlerDepends(app), options))
  },
}

// プラグイン取得
export function useErrorHandler() {
  return pluginInject(ErrorHandlerKey)
}
