<template lang="pug">
.version-mark version {{ver ?? 'N/A'}}
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {useAuth} from '../plugins/Auth'

const {ver} = useAuth()
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.version-mark
  bottom: 0
  color: gray
  font-size: 10px
  opacity: .5
  pointer-events: none
  position: fixed
  right: 4px
  user-select: none
  z-index: 1000000
</style>
