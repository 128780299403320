<template lang="pug">
.x-group(
  ref="el"
  :class="{hide}"
  :data-label="props.label"
)
  slot
</template>

<script setup lang="ts">
import {ref, onMounted, onBeforeUnmount} from 'vue'

const props = withDefaults(defineProps<{
  label?: string|undefined,
}>(), {})

const el = ref<any>(null)

const hide = ref(true)

const resizeObserver = new ResizeObserver((size) => {
  const rect = size[0].contentRect
  hide.value = rect.width === 0 || rect.height === 0
})

onMounted(() => {
  resizeObserver.observe(el.value)
})

onBeforeUnmount(() => {
  resizeObserver.unobserve(el.value)
})
</script>

<style scoped lang="sass">
.x-group
  border: 1px solid black
  border-radius: 10px
  display: flex
  flex-direction: column
  padding: 20px 15px
  position: relative

  &.hide
    position: absolute
    visibility: hidden

  &::before
    background-color: white
    content: attr(data-label)
    padding: 0 10px
    position: absolute
    top: -10px

  :deep(> *:not(:first-child))
    margin-top: 15px

@media(prefers-color-scheme: dark)
  .x-group
    border: 1px solid white

    &::before
      background-color: black
</style>
