<template lang="pug">
auth-guard
  x-root(v-if="isShow")
    template(#top)
      q-item.non-selectable.aq-root-drawer__top
        q-item-section {{authName}}

    template(#bottom)
      aq-root-menu-item(
        icon="logout"
        title="ログアウト"
        @click="logout"
      )

  version-mark
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import XRoot from './components/XRoot.vue'

import VersionMark    from './aax/components/VersionMark.vue'
import AqRootMenuItem from './aax/components-quasar/AqRootMenuItem.vue'

import {useAuth}   from '@/aax/plugins/Auth'
import { computed } from 'vue';

const auth = useAuth()

async function logout() {
  await auth.logout()
}

const isShow = computed(() => auth.auth.value != null)
const authName = computed(() => auth.auth.value?.name ?? '')
</script>

<!----------------------------------------------------------------------------->

<style src="@/assets/globals.sass"></style>
