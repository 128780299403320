
<!-- TargetClient 対象団体 -->

<template lang="pug">
aq-page(fixed)
  template(#header-right)
    q-btn(
      label="保存"
      :color="changed ? 'primary' : 'grey'"
      :disable="!changed"
      @click="save"
    )

  q-table.aq-lazy-table.full-height(
    dense square
    separator="cell"
    :columns="columns"
    :rows="rows"
    :rows-per-page-options="[0]"
  )
    template(#body="props")
      q-tr(:props="props")
        q-td(:props="props" key="enable" auto-width)
          q-toggle(v-model="props.row.enable" dense)

        q-td(:props="props" key="client_id" auto-width)
          | {{props.row.mm_client.client_id}}

        q-td(:props="props" key="client_name")
          | {{props.row.mm_client.name}}
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'
import {cloneDeep, isEqual, pick} from 'lodash-es'

import {TargetClientUpdate} from '@/types/apis/TargetClients'

// inject

import {useAxios} from '@/aax/plugins/Axios'

const axios = useAxios().$scoped()

// カラム構成

const columns: any[] = [
  {label: '対象',   name: 'enable',      align: 'left'},
  {label: '団体ID', name: 'client_id',   align: 'left'},
  {label: '団体名', name: 'client_name', align: 'left'},
]

// データ

const rows     = ref<TargetClientUpdate.RowT[]>([])
const original = ref<TargetClientUpdate.RowT[]>([])
const changed  = computed(() => !isEqual(rows.value, original.value))

onBeforeMount(load)

async function load() {
  rows.value = (await axios.$get<TargetClientUpdate.RowT[]>('/_/api/target-clients')).map(row => ({
    enable: false,
    ...row,
  }))
  original.value = cloneDeep(rows.value)
}

async function save() {
  await axios.$put('/_/api/target-clients',
    rows.value.map(row => pick(row, TargetClientUpdate.Require))
  )
  original.value = cloneDeep(rows.value)
}
</script>
