
// 共用: aax-fastify, aax-vue3

export function sleep(timeout: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, timeout)
  })
}

export async function hangup() {
  for(;;) {
    await sleep(2147483647)
  }
}
