import {isObject} from 'lodash-es'

function addChildren(elForm: HTMLFormElement, prefix: string, data: any) {
  if(isObject(data)) {
    for(const [key, value] of Object.entries(data)) {
      addChildren(elForm, prefix + '[' + key + ']', value)
    }
  }
  else {
    const el = document.createElement('input')
    el.type = 'hidden'
    el.name = prefix
    el.value = data
    elForm.appendChild(el)
  }
}

export function postOpen(url: string, data: any) {
  const elForm = document.createElement('form')
  elForm.method = 'POST'
  elForm.action = url
  elForm.target = '_blank'
  elForm.style.display = 'none'

  for(const [key, value] of Object.entries(data)) {
    addChildren(elForm, key, value)
  }

  document.body.appendChild(elForm)
  elForm.submit()
  document.body.removeChild(elForm)
}

// 参考: https://stackoverflow.com/a/36872763
export function postOpen2(url: string, data: any) {
  const win = window.open('about:blank')
  if (win == null) return

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then((response) => response.text())
  .then((text) => writeToWindow(win,text))
  .catch((error) => console.log(error))
}

function writeToWindow(win: Window, text: string) {
  win.document.open()
  win.document.write(text)
  win.document.close()
}
