
<!-- テーブル形式フォームのカラム入力: 繰越選択 -->

<template lang="pug">
q-field.x-col-carry(
  v-if="disable || carry !== true"
  dense standout="aq-field-standout"
  :class="{red: disable && item.shortage && !item.carry}"
)
  .self-center {{ text }}
q-select.x-col-carry(
  v-else
  dense standout="aq-field-standout"
  options-dense
  emit-value map-options
  :class="{red: !modelValue}"
  :modelValue="modelValue"
  :options="options"
  @update:modelValue="emit('update:modelValue', $event)"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {computed} from 'vue'
import {ClientOffsetSelect} from '@/types/apis/ClientOffsets'
import {Put} from '@/types/apis/ClientOffsetValuesView'

// inject

import {useClientOffsetData} from './XColClientOffsetDataProvider'

const clientOffsetData = useClientOffsetData()

// props

const props = defineProps<{
  modelValue: boolean | null,
  disable: boolean | null,
  item: Put.RowT,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean | null): void,
}>()

//

const options = [
  {value: false, label: 'しない', color: 'red'},
  {value: true,  label: 'する',   color: 'red'},
]

// state

const clientOffsetMap = computed(() => {
  const map: Record<string, ClientOffsetSelect.RowT> = {}
  if (clientOffsetData.value != null) {
    for (const row of clientOffsetData.value) {
      map[row.id] = row
    }
  }
  return map
})

const carry = computed(() => clientOffsetMap.value[props.item.clientOffsetId]?.carry)

const text = computed(() => !props.disable ? 'しない' : (
    !props.item.shortage ? '' : (
      props.item.carry ? '繰越' : '請求'
    )
  )
)
</script>

<!----------------------------------------------------------------------------->

<script lang="ts">
import '@/aax/components-quasar/AqStyles.sass'
</script>

<style lang="sass">
.x-col-carry
  &.red .q-field__native
    color: red !important

  .q-field__control-container
    color: white

  &.red .q-field__control-container
    color: red
</style>
