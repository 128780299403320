
// トースト表示 (quasar使用)

import {App} from 'vue'

import {Notify} from 'quasar'

import {ToastKey, getToastDepends, createToast as createParent} from './Toast'
import type {ToastDepends, ToastOptions, ToastObject} from './Toast'
export * from './Toast'

//--------------------------------------------------------------

// (型) 依存構成
// ToastDepends

// (型) オプション
// ToastOptions

// (型) インスタンス
// ToastObject

//--------------------------------------------------------------

// (内部) インジェクションキー
// ToastKey

// (内部) 依存構成
// getToastDepends

// (内部) インスタンス作成
export function createToast(depends: ToastDepends, options?: ToastOptions): ToastObject {

  const show = (args: any[], opts?: ToastOptions) => {
    const text = args.map(arg => arg?.toString?.() ?? 'unknown').join(' ')
    Notify.create({message: text, position: 'bottom-right', ...opts})
  }

  return {
    ...createParent(depends, options),

    show(severity: string, ...args: any[]) {
      switch(severity) {

        default:
        case 'error':
          show(args, {type: 'negative'})
          break

        case 'warn':
          show(args, {type: 'warning'})
          break

        case 'info':
          show(args, {type: 'info'})
          break

        case 'success':
          show(args, {type: 'positive'})
          break

        case 'update':
          show(args, {timeout: 1000})
          break
      }
    },
  }
}

//--------------------------------------------------------------

// プラグインインストール
export const ToastPlugin = {
  install(app: App, options?: ToastOptions) {
    app.provide(ToastKey, createToast(getToastDepends(app), options))
  },
}

// プラグイン取得
// useToast
