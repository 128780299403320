<template lang="pug">
q-input.aq-filter-input(
  ref="el"
  v-model="text"
  clearable dense standout="aq-field-standout"
  label="検索"
  @keyup.enter="onUpdate"
  @blur="onUpdate"
  @clear="onUpdate"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, watch} from 'vue'
import {QInput} from 'quasar'

const props = defineProps<{
  modelValue: string|null,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|null): void,
}>()

const el = ref<QInput|null>(null)

const text = ref<string|null>(props.modelValue)

watch(() => props.modelValue, value => {text.value = value})

function onUpdate() {
  el.value?.blur()
  emit('update:modelValue', text.value)
}
</script>

<!----------------------------------------------------------------------------->

<script lang="ts">
import './AqStyles.sass'
</script>

<style lang="sass">
.aq-filter-input
  width: 200px
</style>
