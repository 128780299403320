<template lang="pug">
aq-page
  template(
    #header-right
    v-if="!isLoading && !isError"
  )
    q-btn(color="grey" @click="back") 戻る
    q-btn(color="primary" @click="save") 保存

  template(v-if="isLoading")
    div 読み込み中...
  template(v-else-if="isError")
    div NOT FOUND
  template(v-else)
    q-form(
      ref="elQForm"
      v-bind="$attrs"
      @submit.prevent
    )
      slot
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'

import {QForm} from 'quasar'
import {isPlainObject, omit} from 'lodash-es'
import Axios from 'axios'

import {useRouter} from 'vue-router'
import {useAxios} from '@/aax/plugins/Axios'

export type StringMap = {[x: string]: string}

const elQForm = ref<QForm|undefined>(undefined)

// inject
const router = useRouter()
const axios = useAxios().$scoped()

// props
const props = defineProps<{
  apiUrl:     string,
  backTo:     string,
  modelValue: any,
  readonly?:  string[],
  errors?:    StringMap,
}>()

// emits
const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void,
  (e: 'back', result?: any): void,
  (e: 'update:errors', value: StringMap): void,
}>()

// state
const id = computed(() => router.currentRoute.value.params.id)
const isNew = computed(() => id.value === '@new')
const isError = ref(false)
const isLoading = ref(true)

// 読み込み
onBeforeMount(async () => {
  try {
    if(!isNew.value) {
      emit('update:modelValue', {
        ...props.modelValue,
        ...await axios.$get(props.apiUrl + id.value),
      })
    }
  }
  catch(e) {
    if(!Axios.isAxiosError(e) || ![400, 404].includes(e.response?.status ?? 0)) {
      throw e
    }
    isError.value = true
  }
  finally {
    isLoading.value = false
  }
})

// 保存
async function save() {

  if(elQForm.value == null || !(await elQForm.value.validate())) return

  let data = props.modelValue
  if(props.readonly != null) {
    data = omit(data, props.readonly)
  }

  let result: any
  try {
    if(isNew.value) {
      result = await axios.$post(props.apiUrl, data)
    }
    else {
      result = await axios.$put(props.apiUrl + id.value, data)
    }
  }
  catch(e) {
    if(Axios.isAxiosError(e) && e.response?.status === 422 && isPlainObject(e.response.data.errors)) {
      const errors: StringMap = {}
      for(const [prop, reason] of Object.entries(e.response.data.errors as {[x: string]: string[]})) {
        errors[prop] = reason[0]
      }
      emit('update:errors', errors)
      return
    }
    throw e
  }

  await router.push({name: props.backTo})
}

// 破棄
async function back() {
  await router.push({name: props.backTo})
}
</script>
