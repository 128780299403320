
<!-- Bank 銀行 [一覧] -->

<template lang="pug">
//- 設定
x-list(
  api-url        = "/_/api/banks"
  api-count-url  = "/_/api/banks/@count"
  next-to        = "banksDetail"
  :columns       = "columns"
  :row-key       = "Crud.PrimaryKey"
  :rows-per-load = "100"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import type {ColumnsT} from '@/aax/components-quasar/AqLazyTable.vue'

import {Crud} from '@/types/apis/Banks'

// カラムUI定義
const columns: ColumnsT = [
  {
    label: '金融機関コード',
    name: 'code',
    sortable: true,
    headerClasses: 'q-table--col-auto-width',
  },  // String?

  {
    label: '名称',
    name: 'name',
    sortable: true,
  },  // String?

  {
    label: 'カナ',
    name: 'kana',
    sortable: true,
  },  // String?
]
</script>
