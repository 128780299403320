<template lang="pug">
q-input.x-field-date(
  ref="elQInput"
  v-model="text"
  v-bind="$attrs"
  filled
  unmasked-value mask="####年##月##日"
  lazy-rules="ondemand" :rules="[rule]"
  @keyup="onKeyup"
  @blur="onBlur"
)
  template(#append)
    q-icon.q-field__focusable-action.cursor-pointer(
      v-if="text"
      name="cancel"
      @click.stop="onClear"
    )

    q-icon.cursor-pointer(
      name="event"
      @click="open = !open"
    )

  q-menu(
    v-model="open"
    no-parent-event
  )
    q-date(
      minimal
      mask="YYYYMMDD"
      :modelValue="text"
      @update:modelValue="onDateUpdate"
    )
      .row.justify-between.items-center
        q-btn(flat v-close-popup label="今日" @click="onDateToday")
        q-btn(flat v-close-popup label="閉じる")
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, watch, nextTick} from 'vue'
import type {QInput, QDate} from 'quasar'
import {format, formatISO, parseISO, isValid} from 'date-fns'
import {isString} from 'lodash-es'

const InvalidDate = 'Invalid Date'

// props
const props = defineProps<{
  modelValue: string|null,
}>()

// emits
const emit = defineEmits<{
  (e: 'update:modelValue', value: string|null): void,
}>()

// state
const elQInput = ref<QInput|null>(null)
const text = ref<string|null>(toDate(props.modelValue))
const open = ref(false)

watch(() => props.modelValue, value => {
  if(value !== InvalidDate) {
    text.value = toDate(value)
  }
})

function interpolateYearMonthDay() {
  if(text.value != null) {
    if(text.value.length < 4) {
      text.value = format(new Date, 'yyyy').slice(0, 4 - text.value.length) + text.value
      cursorAtLast()
    }
    else interpolateMonthDay()
  }
}

function interpolateMonthDay() {
  if(text.value != null) {
    if(text.value.length === 4) {
      text.value += format(new Date, 'MM')
      cursorAtLast()
    }
    else if(text.value.length === 5) {
      text.value = text.value.slice(0, 4) + '0' + text.value.slice(4)
      cursorAtLast()
    }
    else interpolateDay()
  }
}

function interpolateDay() {
  if(text.value != null) {
    if(text.value.length === 6) {
      text.value += format(new Date, 'dd')
      cursorAtLast()
    }
    else if(text.value.length === 7) {
      text.value = text.value.slice(0, 6) + '0' + text.value.slice(6)
      cursorAtLast()
    }
  }
}

async function onKeyup(ev: KeyboardEvent) {
  if(['-', '/'].includes(ev.key)) {
    interpolateYearMonthDay()
  }
  else if(ev.key === 'Enter') {
    if(text.value != null && 0 < text.value.length) {
      interpolateYearMonthDay()
      if(text.value.length === 8) {
        await validate()
      }
    }
  }
  else if(ev.key === 'Escape') {
    await onClear()
  }
}

async function onBlur() {
  interpolateDay()
  await validate()
}

async function onClear() {
  text.value = null
  await validate()
}

watch(text, value => {
  emit('update:modelValue', toISO(value))
})

// QDate

const onDateUpdate: QDate['onUpdate:modelValue'] = async value => {
  if(isString(value)) text.value = value
  open.value = false
  await validate()
}

async function onDateToday() {
  text.value = toDate(new Date)
  await validate()
}

//

async function validate() {
  await nextTick()
  elQInput.value?.validate()
}

function rule(value: string|number|null) {
  return toISO(value) !== InvalidDate
}

//

function toDate(value: string|Date|null) {
  if(value == null) return ''
  const date = value instanceof Date ? value : parseISO(value)
  return isValid(date) ? formatISO(date, {format: 'basic', representation: 'date'}) : ''
}

function toISO(value: string|number|null) {
  if(value == null || value === '') return null
  if(isString(value) && /^\d{8}$/.test(value)) {
    const date = parseISO(value + 'T000000Z')
    if(isValid(date)) return date.toISOString()
  }
  return InvalidDate
}

function cursorAtLast() {
  setTimeout(() => {
    const [elInput] = elQInput.value!.$el.getElementsByClassName('q-field__native')
    elInput.selectionStart = Number.MAX_SAFE_INTEGER
    elInput.selectionEnd   = Number.MAX_SAFE_INTEGER
  }, 0)
}
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.x-field-date
  width: 290px
</style>
