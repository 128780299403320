
import {App, ref, computed} from 'vue'

// 依存プラグイン
import {eventUnauthorized} from './Axios'

import {AuthKey, getAuthDepends, loadIntro} from './Auth'
import type {AuthDepends, AuthOptions, AuthObject, AuthData} from './Auth'
export * from './Auth'

// コンポーネント
import AuthGuardSaml from '../components/AuthGuardSaml.vue'

//--------------------------------------------------------------

// (型) 依存構成
// AuthDepends

// (型) オプション
// AuthOptions

// (型) インスタンス
// AuthObject

// (型) 認証データ型
// AuthData

//--------------------------------------------------------------

// (内部) インジェクションキー
// AuthKey

// (内部) 依存構成
// getAuthDepends

// (内部) インスタンス作成
export function createAuth(depends: AuthDepends, options?: AuthOptions): AuthObject {
  const {app, eventBus, toast, axios} = depends

  // オプションのデフォルト値を適用する
  // MEMO: import.meta.env.BASE_URL は URL Prefix の指定がないときは /、あるときは /foobar/ の形式をとる
  const final: Required<AuthOptions> = {
    introPath:  '/_/api/intro',
    loginPath:  import.meta.env.BASE_URL + '_/sso/login',
    logoutPath: import.meta.env.BASE_URL + '_/sso/logout',

    ...options,
  }

  //
  let state = ref<AuthData>({})
  let loading = false

  const obj = {

    ver:  computed(() => state.value.ver),
    auth: computed(() => state.value.auth),

    async load() {
      if(loading !== true) {
        loading = true
        try {
          state.value = await axios.$get(final.introPath)
        }
        finally {
          loading = false
        }

        // バージョン出力
        if(import.meta.env.PROD) {
          console.log('version', state.value.ver ?? 'N/A')
        }
      }
    },

    async login() {
      location.href = final.loginPath
    },

    async logout() {
      location.href = final.logoutPath
    },
  }

  eventBus.on(eventUnauthorized, async () => {
    if(obj.auth.value !== undefined) {
      try {
        await obj.load()
      }
      catch {}
      if(obj.auth.value === undefined) {
        toast.show('error', 'ログアウトしました')
      }
    }
  })

  // コンポーネント
  app.component('AuthGuard', AuthGuardSaml)

  return obj
}

// (内部) 初期情報取得
// loadIntro

//--------------------------------------------------------------

// プラグインインストール
export async function loadAuthPlugin(app: App, options?: AuthOptions) {
  const obj = createAuth(getAuthDepends(app), options)
  await loadIntro(obj)

  return {
    install(app: App) {
      app.provide(AuthKey, obj)
    },
  }
}

// プラグイン取得
// useAuth
