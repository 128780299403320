
<!-- テーブル形式フォームのカラム入力: 生産者選択 -->

<template lang="pug">
q-select.x-col-producer(
  ref="elQSelect"
  dense standout="aq-field-standout"
  options-dense
  emit-value map-options
  fill-input hide-selected use-input input-debounce="0"
  :class="{'x-col-producer__loading': loading, 'x-col__error': error}"
  :loading="loading"
  :modelValue="modelValue"
  :options="options"
  @filter="onFilter"
  @keydown.enter="onKeyDownEnter"
  @update:modelValue="onUpdate"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, watch, watchEffect, nextTick} from 'vue'
import type {QSelect} from 'quasar'

import {sleep} from '@/aax/libs/sleepAsync'
import {Common, ProducerSearch} from '@/types/apis/Producers'

import {testRules} from './XColRules'
import type {Rules} from './XColRules'

// inject

import {useProducerData} from './XColProducerDataProvider'

const producerData = useProducerData()

// props

const props = defineProps<{
  modelValue: number|null,
  producerData?: ProducerSearch.RowT,
  rules?: Rules,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number|null): void,
  (e: 'update', value: Common.RowT|null): void,
}>()

// バリデーション

const error = ref(false)

watchEffect(async () => {
  error.value = !await testRules(props.rules, props.modelValue)
})

// state

const elQSelect = ref<QSelect|null>(null)

const loading = computed(() => producerData.data.value == null)

const additionalProducer = ref<ProducerSearch.RowT|undefined>(undefined)

const producers = computed(() => {
  if(producerData.data.value == null) return []
  if(additionalProducer.value == null) return producerData.data.value

  if(producerData.data.value.some(row => row.producerId === additionalProducer.value?.producerId)) {
    return producerData.data.value
  }

  const data = [...producerData.data.value]

  const row = additionalProducer.value
  data.unshift({
    value: row.producerId,
    label: row.producerId + ' ' + row.producerName + '【' + row.clientName + '】',
    ...row,
  })

  return data
})

const producerMap = computed(() => {
  const map: {[x: number]: typeof producers.value[0]} = {}
  for(const row of producers.value) map[row.value] = row
  return map
})

const filter = ref('')
const options = computed(() =>
  filter.value === ''
    ? producers.value
    : producers.value.filter(c => c.label.includes(filter.value))
)

async function update(value: number|null) {
  emit('update:modelValue', value)

  if(value != null) {
    const producer = producerMap.value[value]
    if(producer != null) {
      emit('update', producer)
    }
  }
}

// events

const onFilter: QSelect['onFilter'] = (value, update) => {
  update(() => {
    filter.value = value
  })
}

async function onUpdate(value: number|null) {
  update(value)
}

// 団体外生産者検索

watch(() => props.modelValue, value => {
  if(value != null && value !== 0) {
    const producer = producerMap.value[value]
    if(producer == null && props.producerData != null) {
      additionalProducer.value = {
        producerId: props.producerData.producerId,
        producerName: props.producerData.producerName,
        clientId: props.producerData.clientId,
        clientName: props.producerData.clientName,
      }
    }
  }
}, {immediate: true})

async function onKeyDownEnter(ev: KeyboardEvent) {
  if(/^\d{4}$/.test(filter.value)) {
    const producerId = parseInt(filter.value, 10)

    const producer = producerMap.value[producerId]
    if(producer != null) {
      await refreshSelect(producerId)
      return
    }

    additionalProducer.value = await producerData.search(producerId)
    if(additionalProducer.value != null) {
      await refreshSelect(producerId)
    }
  }
}

async function refreshSelect(producerId: number) {
  update(producerId)
  await nextTick()
  elQSelect.value?.blur()
  await sleep(0)
  elQSelect.value?.focus()
}
</script>

<!----------------------------------------------------------------------------->

<script lang="ts">
import '@/aax/components-quasar/AqStyles.sass'
</script>

<style lang="sass">
.x-col-producer
  .q-field__native
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &.x-col-producer__loading
    .q-field__native
      visibility: hidden
</style>
