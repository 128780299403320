<template lang="pug">
q-select.x-target-client(
  v-if="auth.hasRoles('admin')"
  dense options-dense standout="aq-field-standout"
  emit-value map-options
  fill-input hide-selected use-input input-debounce="0"
  label="団体"
  :class="{'x-target-client--loading': loading}"
  :loading="loading"
  :modelValue="modelValue"
  :options="options"
  @filter="onFilter"
  @update:modelValue="v => emit('update:modelValue', v)"
)
  template(#append)
    q-icon.q-field__focusable-action.cursor-pointer(
      v-if="!noClearable && modelValue"
      name="cancel"
      @click.stop="emit('update:modelValue', null)"
    )
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'
import type {QSelect} from 'quasar'

import {TargetClientSelect} from '@/types/apis/TargetClients'

// inject

import {useAxios} from '@/aax/plugins/Axios'
import {useAuth} from '@/plugins/Auth'

const axios = useAxios().$scoped()
const auth = useAuth()

// props

const props = withDefaults(defineProps<{
  modelValue?: number|null,
  noClearable?: boolean,
}>(), {
  noClearable: false,
})

// emits

const emit = defineEmits<{
  (e: 'update:modelValue', value: number|null): void,
}>()

// state

type OptionsT = {
  value: number,
  label: string,
}

const clients = ref<OptionsT[]>([])
const loading = ref(true)

const filter = ref('')
const options = computed(() =>
  filter.value === ''
    ? clients.value
    : clients.value.filter(c => c.label.includes(filter.value))
)

// events

onBeforeMount(async () => {
  try {
    const select = await axios.$get<TargetClientSelect.RowT[]>('/_/api/target-clients/@select')
    clients.value = select.map(row => ({
      value: row.id,
      label: row.id + ' ' + row.name,
    }))

    if(props.noClearable) {
      if(props.modelValue == null || clients.value.every(row => row.value !== props.modelValue)) {
        emit('update:modelValue', clients.value[0].value)
      }
    }
    else if(props.modelValue != null && clients.value.every(row => row.value !== props.modelValue)) {
      emit('update:modelValue', null)
    }
  }
  finally {
    loading.value = false
  }
})

const onFilter: QSelect['onFilter'] = (value, update) => {
  update(() => {
    filter.value = value
  })
}

// BUG: QSelectドロップダウンの初期の幅がおかしい問題: Quasarのバグ
// 類似Issue: https://github.com/quasarframework/quasar/issues/11453
// 再現Fiddle: https://jsfiddle.net/sinbino/gu2s0L4d/7/
</script>

<!----------------------------------------------------------------------------->

<script lang="ts">
import '@/aax/components-quasar/AqStyles.sass'
</script>

<style lang="sass">
.x-target-client
  width: 200px

  .q-field__native
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &.x-target-client--loading
    .q-field__native
      visibility: hidden
</style>
