
<!-- ClientOffsetValue 相殺金額 -->

<template lang="pug">
aq-page(fixed :loading="loading")
  template(#header-right)
    q-btn(
      label="集計表"
      :color="'secondary'"
      @click="onAggregate"
    )

    x-target-client(v-model="searchClientId" dense no-clearable)
    x-year-month(v-model="searchYearMonth" dense no-clearable)

    q-btn(
      label="保存"
      :color="notTouch ? 'grey' : 'primary'"
      :disable="notTouch"
      @click="onSave"
    )

  q-virtual-scroll(
    ref="elVirtualScrollRef"
    style="max-height: 100%"
    :items="data"
    :virtual-scroll-item-size="44"
    :virtual-scroll-sticky-size-start="46"
  )
    template(#before)
      div(:class="headerClass")
        q-item.items-center.q-pa-xs(dense)
          x-col-header.col.client-offset-values__col-status.q-mr-xs
          x-col-header.col.x-col-date.q-mr-xs 相殺日
          x-col-header.col.x-col-client-offset.q-mr-xs 相殺項目
          x-col-header.col.client-offset-values__col-carry.q-mr-xs 繰越
          x-col-header.col.q-mr-xs 生産者
          x-col-header.col.client-offset-values__col-quantity.q-mr-xs 個数
          x-col-header.col.client-offset-values__col-value.q-mr-xs 金額
          x-col-header.col.q-mr-xs 備考

          q-btn.invisible(
            v-if="!disable"
            tabindex="-1"
          ) 削除

        .q-px-xs.q-pb-xs(v-if="!disable")
          q-btn.full-width(
            color="primary"
            @click="onAdd"
          ) 行を追加する

    template(#default="{index, item}")
      q-item.items-center.q-px-xs(
        dense
        :key="item.id"
      )
        div.col.client-offset-values__col-status.q-mr-xs
          template(v-if="item.carried")
            x-col-header 繰
            q-tooltip 繰越元相殺日 : {{format(new Date(), 'yyyy-MM-dd')}}
          x-col-header(v-else)

        x-col-date.col.q-mr-xs(
          v-model="item.processed"
          :disable="disable"
          :rules="[validations.processed]"
          :month="searchYearMonth"
        )

        x-col-client-offset.col.q-mr-xs(
          v-model="item.clientOffsetId"
          :disable="disable"
          :rules="[validations.clientOffsetId]"
        )

        x-col-carry.col.client-offset-values__col-carry.q-mr-xs(
          v-model="item.carry"
          :disable="disable"
          :item="item"
        )

        x-col-producer.col.q-mr-xs(
          :modelValue="item.producerId"
          :producerData="item"
          :disable="disable"
          :rules="[validations.producerId]"
          @update="(v: ProducerSelect.RowT) => {onUpdateProducer(item, v)}"
        )

        x-col-text.col.client-offset-values__col-quantity.q-mr-xs(
          v-model="item.quantity"
          :disable="disable"
          :rules="[validations.quantity]"
          number mask="##########" input-class="text-right"
        )

        x-col-text.col.client-offset-values__col-value.q-mr-xs(
          v-model="item.value"
          :disable="disable"
          :rules="[validations.value]"
          number mask="##########" input-class="text-right"
        )

        x-col-text.col.q-mr-xs(
          v-model="item.remarks"
          :disable="disable"
          :maxlength="250"
        )

        q-btn(
          v-if="!disable"
          color="negative"
          tabindex="-1"
        ) 削除
          q-menu.bg-negative(
            anchor="center right"
            self="center left"
            :offset="[5,0]"
          )
            q-list.bg-negative
              q-item(
                color="negative"
                clickable v-close-popup
                @click="onDelete(index)"
              )
                q-item-section この行を削除します
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, watch, onBeforeMount, nextTick} from 'vue'
import {format, formatISO, isBefore, isValid, parse, startOfDay, startOfMonth} from 'date-fns'
import {cloneDeep, isEqual, omit} from 'lodash-es'
import {QVirtualScroll} from 'quasar'

import {getIntQuery, getStringQuery} from '@/aax/libs/vue-router'
import {Put}                         from '@/types/apis/ClientOffsetValuesView'

import {leaveGuard} from '@/aax/libs/leaveGuard'

// injects

import {useRouter} from 'vue-router'
import {useQuasar} from 'quasar'
import {useAxios, testAxiosError} from '@/aax/plugins/Axios'
import {useDialog} from '@/aax/plugins/Dialog'

// provides

import {provideClientOffsetData} from '@/components/XColClientOffsetDataProvider'
import {provideProducerData}     from '@/components/XColProducerDataProvider'

// バリデーション

import {makeMonthRangeTest} from '@/types/libs/makeMonthRange'

import {ProducerSelect} from '@/types/apis/Producers'

// injects

const router = useRouter()
const $q = useQuasar()
const axios = useAxios().$scoped()
const dialog = useDialog()

// provides

provideClientOffsetData()
const producerData = provideProducerData({initLoad: false})

// ダークモード対応

const headerClass = computed(() => 'client-offset-values__header--' + ($q.dark.isActive ? 'dark' : 'light'))

// 前月以前は編集不可

const currentDate = ref(startOfMonth(new Date))

const disable = computed(() => {
  if(searchYearMonth.value == null) return true
  const minimumDate = parse(searchYearMonth.value, 'yyyyMM', 0)
  return !isValid(minimumDate) || isBefore(minimumDate, currentDate.value)
})

// 絞り込み

const searchYearMonth = ref(getStringQuery(router.currentRoute.value.query.month))
const searchClientId = ref(getIntQuery(router.currentRoute.value.query.client_id))

watch([searchYearMonth, searchClientId], async () => {
  await router.replace({query: {
    month: searchYearMonth.value ?? undefined,
    client_id: searchClientId.value ?? undefined,
  }})
})

// データ

const data = ref<Put.RowT[]>([])
const loading = ref(false)

const original = ref<Put.RowT[]>([])
const notTouch = computed(() => disable.value || isEqual(data.value, original.value))

let lastUrl: string|undefined

function getApiUrl() {
  return (searchYearMonth.value == null || searchClientId.value == null) ? undefined
    : '/_/api/client-offset-values/@view/' + searchYearMonth.value + '/' + searchClientId.value
}

async function loadData(force: boolean = false) {
  const url = getApiUrl()
  if(url != null && (force || url !== lastUrl)) {
    lastUrl = url
    try {
      loading.value = true
      data.value = []
      original.value = []
      data.value = await axios.$get(url)
      original.value = cloneDeep(data.value)
    }
    finally {
      loading.value = false
    }
  }
}

async function loadProducerData() {
  if(searchClientId.value != null) {
    await producerData.load(searchClientId.value)
  }
}

onBeforeMount(async () => {
  await Promise.all([
    loadData(),
    loadProducerData(),
  ])
})

watch([searchYearMonth, searchClientId], async () => {
  await loadData()
})

watch(searchClientId, async () => {
  await loadProducerData()
})

function onUpdateProducer(row: Put.RowT, select: ProducerSelect.RowT) {
  row.producerId = select.producerId
  row.producerName = select.producerName
}

// 保存

async function onSave() {
  if(!validateAll()) {
    await dialog.alert({message: 'シートを正しく記入してください'})
    return
  }

  const url = getApiUrl()
  if(url != null) {
    try {
      loading.value = true
      await axios.$put(url, data.value.map(row => omit(row, Put.Readonly)))
    }
    catch(e) {
      if(!testAxiosError(e, [400, 404])) throw e
      await dialog.alert({message: 'このシートの更新は締め切られています'})
      return
    }
    finally {
      loading.value = false
    }
  }
  await loadData(true)
}

// 行を追加

const elVirtualScrollRef = ref<QVirtualScroll|null>(null)

async function onAdd() {
  data.value.unshift({
    processed:      formatISO(startOfDay(new Date)),
    quantity:       1,
    value:          null,
    remarks:        '',

    carry:          true,
    carried:        null,
    shortage:       false,

    clientOffsetId: '',
    producerId:     0,
  })

  await nextTick()
  elVirtualScrollRef.value?.scrollTo(0, 'end')
}

// 行を削除

async function onDelete(index: number) {
  data.value.splice(index, 1)
}

// バリデーション

const monthRangeTest = computed(() => makeMonthRangeTest(searchYearMonth.value) ?? (() => false))

const validations = {
  processed:      (v?: string|null) => monthRangeTest.value(v),
  clientOffsetId: (v?: string|null) => v != null && 0 < v.length,
  producerId:     (v?: number|null) => v != null && 0 < v,
  quantity:       (v?: number|null) => v != null,
  value:          (v?: number|null) => v != null,
}

function validateAll() {
  for(const [index, row] of data.value.entries()) {
    for(const [key, validation] of Object.entries(validations)) {
      if(!validation((row as any)[key])) {
        elVirtualScrollRef.value?.scrollTo(index, 'center')
        return false
      }
    }
  }
  return true
}

// 編集破棄ガード

leaveGuard(() => !notTouch.value)

// 日付・項目別集計表を開く

function onAggregate() {
  const urlObject = new URL('aggregate', window.location.origin)
  urlObject.search = new URLSearchParams(router.currentRoute.value.query as any).toString()
  window.open(urlObject.href, '_blank')
}
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.client-offset-values__header--light,
.client-offset-values__header--dark
  margin-bottom: -2px
  position: sticky
  top: 0
  z-index: 1000

.client-offset-values__header--light
  background-color: white

  .client-offset-values__text
    color: black

.client-offset-values__header--dark
  background-color: var(--q-dark-page)

  .client-offset-values__text
    color: white

.client-offset-values__col-status
  max-width: 40px !important
  text-align: center

.client-offset-values__col-quantity,
.client-offset-values__col-value
  max-width: 104px !important

.client-offset-values__col-carry
  max-width: 100px !important
</style>
