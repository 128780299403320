
// テーブル形式フォームのカラム入力: 相殺項目選択

import {ref, readonly, provide, inject, InjectionKey} from 'vue'
import {useAxios} from '@/aax/plugins/Axios'
import {ClientOffsetSelect} from '@/types/apis/ClientOffsets'

// インスタンス
type Object = ReturnType<typeof createInstance>

// インジェクションキー
const Key: InjectionKey<Object> = Symbol('x-client-offset-data')

// インスタンス作成
function createInstance() {
  const data = ref<ClientOffsetSelect.RowT[]|undefined>(undefined)

  const axios = useAxios()
  ;(async () => {
    data.value = await axios.$get('/_/api/client-offsets/@select')
  })()

  return readonly(data)
}

// インスタンス登録
export function provideClientOffsetData() {
  const obj = createInstance()
  provide(Key, obj)
  return obj
}

// インスタンス取得
export function useClientOffsetData() {
  return inject(Key) ?? provideClientOffsetData()
}
