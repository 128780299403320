<template lang="pug">
q-table.aq-lazy-table(
  v-model:pagination="pagination"
  v-bind="$attrs"
  dense square virtual-scroll
  separator="cell"
  :columns="modifiedColumns"
  @request="onRequest"

  :loading="loading"
  :row-key="rowKey"
  :rows="rows"
  :rows-per-page-options="[0]"
  @virtual-scroll="onVirtualScroll"

  :selected="selected"
  :selection="selection"
  @row-click="onRowClick"
  @row-dblclick="onRowDblclick"
  @row-contextmenu="onRowContextmenu"
  @update:selected="onUpdateSelected"
)
  template(#pagination)
    div(v-if="total != null") 全{{total}}行
    div(v-else) {{rows.length}}行〜
</template>

<!----------------------------------------------------------------------------->

<script lang="ts">
import {ref, computed, watch, onBeforeMount} from 'vue'
import type {QTableProps} from 'quasar'
import Axios from 'axios'
import {omit, pick} from 'lodash-es'

import type {PartialOf, Unarray} from '../libs/utilityTypes'
import * as TableOps from '../libs/prismaTableOpsTypes'

type LooseDictionary = {[x: string]: any}

// injects

import {useRouter} from 'vue-router'
import {useAxios} from '../plugins/Axios'

// props

export type ColumnsT = (PartialOf<Unarray<NonNullable<QTableProps['columns']>>, 'field'> & {
  fieldMap?: {value: any, label: string}[],
})[]

export type SelectionT = QTableProps['selection']
export type SelectedT  = QTableProps['selected']

// emits

export type OnRequestT            = (prop: Parameters<NonNullable<QTableProps['onRequest']>>[0]) => void
export type OnUpdateQueryT        = (query: QuerystringT) => void

// 選択

export type OnRowClickT           = (evt: LooseDictionary, row: LooseDictionary, index: number) => void
export type OnRowDblclickT        = (evt: LooseDictionary, row: LooseDictionary, index: number) => void
export type OnUpdateSelectedT     = (value: any[]) => void

// 検索

export type OnUpdateSearchTextT   = (value: string|null) => void
export type OnUpdateSearchColumnT = (value: string|null) => void

// クエリ文字列 (検索パラメータ, ソートパラメータ)

const QuerystringKeys = ['w', 'wc', 'o', 'od'] as const
export type QuerystringT = Pick<TableOps.T, typeof QuerystringKeys[number]>

</script>

<script setup lang="ts">

// injects

const router = useRouter()
const axios = useAxios().$scoped()

// props -----------------------------------------------------------------------

const props = withDefaults(defineProps<{
  columns:        ColumnsT,

  // 遅延データ読込
  apiUrl:         string,
  apiCountUrl?:   string,
  offsetMethod?:  'cursor'|'offset',
  rowKey?:        string,
  rowsPerLoad?:   number,

  // 選択
  selectableRow?: boolean,
  selection?:     SelectionT,
  selected?:      SelectedT,

  // 検索
  searchText?:    string|null,
  searchColumn?:  string|null,
  additionalQuery?: {[k: string]: string|number}|false,

  // eagerモード
  eager?:         boolean,
}>(), {

  // 遅延データ読込
  offsetMethod:   'offset',
  rowKey:         'id',

  // 選択
  selection:      'none',
  selected:       () => [],

  // 検索
  additionalQuery: undefined,
})

// emits -----------------------------------------------------------------------

const emit = defineEmits<{
  (e: 'request',             prop: Parameters<NonNullable<QTableProps['onRequest']>>[0]): void
  (e: 'updateQuery',         query: QuerystringT): void

  // 選択
  (e: 'rowClick',            evt: LooseDictionary, row: LooseDictionary, index: number): void
  (e: 'rowDblclick',         evt: LooseDictionary, row: LooseDictionary, index: number): void
  (e: 'rowContextmenu',      evt: LooseDictionary, row: LooseDictionary, index: number): void
  (e: 'update:selected',     value: any[]): void

  // 検索
  (e: 'update:searchText',   value: string|null): void
  (e: 'update:searchColumn', value: string|null): void
}>()

// -----------------------------------------------------------------------------

// カラム構成

const modifiedColumns = computed(() => props.columns?.map(col => {
  const col0 = {
    ...col,
    align: col.align ?? 'left',
    field: col.field ?? col.name,
  }

  if(col0.fieldMap != null) {
    const field = col0.field
    const getField = field instanceof Function
      ? (row: LooseDictionary) => field(row)
      : (row: LooseDictionary) => row[field]

    const fieldMap = new Map<any, string>()
    for(const {value, label} of col0.fieldMap) {
      fieldMap.set(value, label)
    }
    col0.field = row => fieldMap.get(getField(row)) ?? ''
    delete col0.fieldMap
  }

  return col0
}))

// クエリ文字列 (検索パラメータ, ソートパラメータ)

const query: QuerystringT = router.currentRoute.value.query

emit('update:searchText', query.w ?? null)
emit('update:searchColumn', query.wc ?? null)

watch(() => props.searchText, updateQueryString)
watch(() => props.searchColumn, updateQueryString)
watch(() => props.additionalQuery, updateQueryString)

const pagination = ref<Required<NonNullable<QTableProps['pagination']>>>({
  sortBy:      query.o ?? '',
  descending:  query.od === 'd',
  page:        1,
  rowsPerPage: 0,
  rowsNumber:  0,
})

const onRequest: QTableProps['onRequest'] = async prop => {
  emit('request', prop)
  pagination.value.sortBy = prop.pagination.sortBy
  pagination.value.descending = prop.pagination.descending
  await updateQueryString()
}

async function updateQueryString() {
  // additionalQueryにfalseを返すとロードを抑止する
  if(props.additionalQuery === false) return

  const query = omit(router.currentRoute.value.query, QuerystringKeys)

  if(props.searchText) query.w = props.searchText
  if(props.searchColumn) query.wc = props.searchColumn

  if(pagination.value.sortBy) {
    query.o = pagination.value.sortBy
    if(pagination.value.descending) query.od = 'd'
  }

  if(props.additionalQuery != null) {
    for(const [key, value] of Object.entries(props.additionalQuery)) {
      if(value == null) delete query[key]
      else (query as {[k: string]: string|number})[key] = value
    }
  }

  await router.replace({query})
  emit('updateQuery', query)
  await loadFirstRows()
}

// 遅延データ読込

const rows = ref<any[]>([])
const loading = ref(false)
const bottom = ref(0)
const total = ref<number|null>(null)

onBeforeMount(loadFirstRows)

async function loadFirstRows() {
  if(0 < props.selected.length) {
    emit('update:selected', [])
  }

  axios.$cancel()

  rows.value = []
  bottom.value = 0
  total.value = null

  const apiCountUrl = props.apiCountUrl
  if(apiCountUrl == null) {
    await loadNextRows()
  }
  else {
    await Promise.all([
      (async () => {
        const params = pick(router.currentRoute.value.query, ['w', 'wc']) as TableOps.T
        const count = await axios.$get<number>(apiCountUrl, {params: {...params, ...(props.additionalQuery || undefined)}})
        if(total.value == null) total.value = count
      })(),
      loadNextRows(),
    ])
  }
}

const onVirtualScroll = async ({to}: {to: number}) => {
  bottom.value = to + 1
  await loadNextRows()
}

async function loadNextRows() {
  // additionalQueryにfalseを返すとロードを抑止する
  if(props.additionalQuery === false) return

  if(!loading.value) {
    try {
      loading.value = true
      while((total.value == null || rows.value.length < total.value) && rows.value.length <= bottom.value) {

        const params = pick(router.currentRoute.value.query, QuerystringKeys) as TableOps.T
        if(0 < rows.value.length) {
          if(props.offsetMethod === 'cursor') {
            params.c = rows.value[rows.value.length - 1][props.rowKey]
          }
          else {
            params.i = rows.value.length
          }
        }
        if(props.rowsPerLoad != null) params.n = props.rowsPerLoad

        const data = await axios.$get<any[]>(props.apiUrl, {params: {...params, ...props.additionalQuery}})
        rows.value.push(...data)
        if(data.length === 0 || (props.rowsPerLoad != null && data.length < props.rowsPerLoad) || props.eager) total.value = rows.value.length
      }
    }
    catch(e) {
      if(!Axios.isCancel(e)) {
        total.value = rows.value.length
        throw e
      }
    }
    finally {
      loading.value = false
    }
  }
}

// 選択

const onUpdateSelected: QTableProps['onUpdate:selected'] = newSelected => {
  emit('update:selected', newSelected as any[]) // QTableProps['onUpdate:selected']のnewSelectedにreadonlyがついたので強制的に外す
}

let lastRowClick = 0

function updateMultipleSelected(evt: LooseDictionary, row: LooseDictionary, index: number) {
  if(props.selection === 'multiple') {
    if(evt.ctrlKey || evt.metaKey) {
      const selected = [...props.selected]
      const i = selected.findIndex(r => r[props.rowKey] === row[props.rowKey])
      if(i === -1) selected.push(row)
      else         selected.splice(i, 1)
      emit('update:selected', selected)
      lastRowClick = index
      return true
    }

    if(evt.shiftKey) {
      let i = lastRowClick
      const selected = [rows.value[i]]
      if(index < i) while(index <= --i) selected.push(rows.value[i])
      else          while(++i <= index) selected.push(rows.value[i])
      emit('update:selected', selected)
      return true
    }
  }
  return false
}

const onRowClick: QTableProps['onRowClick'] = (evt, row, index) => {
  if(props.selection === 'none' || props.selectableRow === false) {
    emit('rowClick', evt, row, index)
    return
  }

  if(!updateMultipleSelected(evt, row, index)) {
    lastRowClick = index
    emit('update:selected', [row])
  }
}

const onRowContextmenu: QTableProps['onRowContextmenu'] = (evt, row, index) => {
  if(props.selection === 'none' || props.selectableRow === false) {
    emit('rowContextmenu', evt, row, index)
    return
  }

  if(!updateMultipleSelected(evt, row, index)) {
    if(!props.selected.some(r => r[props.rowKey] === row[props.rowKey])) {
      lastRowClick = index
      emit('update:selected', [row])
    }
  }
}

// チェックボックスのダブルクリックイベント発生を抑制する

const onRowDblclick: QTableProps['onRowDblclick'] = (evt, row, index) => {
  if(props.selectableRow && evt.target instanceof Element && evt.target.closest('td.q-table--col-auto-width')?.querySelector('div[role=checkbox]') != null) return
  emit('rowDblclick', evt, row, index)
}

// 外部メソッド

async function reload() {
  await loadFirstRows()
}

defineExpose({
  reload,
})

// TODO: q-tableのvirtual-scrollはスクリーンをリサイズしたときに描画漏れを起こすことがある。リサイズを検出して再描画をかけられないか？
// TODO: カラムがない部分を左/右クリックしたとき、選択を全解除できないか？
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
$table-header-light: darken(white, 7)   !default
$table-header-dark:  lighten($dark, 15) !default

.aq-lazy-table
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: $table-header-light

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  .q-virtual-scroll__content
    user-select: none

  &.q-dark
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th
      background-color: $table-header-dark
</style>
