
import type {LocationQueryValue} from 'vue-router'
import {isArray, isString} from 'lodash-es'

// route.query.q から文字列を取得する。存在しない場合は null
export function getStringQuery(q: LocationQueryValue|LocationQueryValue[]) {
  const s = isArray(q) ? q[0] : q
  return isString(s) ? s : null
}

// route.query.q から整数を取得する。存在しないor整数として解釈できない場合は null
export function getIntQuery(q: LocationQueryValue|LocationQueryValue[]) {
  const s = isArray(q) ? q[0] : q
  const n = parseInt(s as any, 10)
  return isNaN(n) ? null : n
}
