
export function validateZengin(text: string) {
  return /^[ ()\-.0-9A-Zｱ-ﾟ]*$/.test(text)
}

const convertZenginTable: {[x: string]: string} = {
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',

  'a': 'A', 'ａ': 'A', 'Ａ': 'A',
  'b': 'B', 'ｂ': 'B', 'Ｂ': 'B',
  'c': 'C', 'ｃ': 'C', 'Ｃ': 'C',
  'd': 'D', 'ｄ': 'D', 'Ｄ': 'D',
  'e': 'E', 'ｅ': 'E', 'Ｅ': 'E',
  'f': 'F', 'ｆ': 'F', 'Ｆ': 'F',
  'g': 'G', 'ｇ': 'G', 'Ｇ': 'G',
  'h': 'H', 'ｈ': 'H', 'Ｈ': 'H',
  'i': 'I', 'ｉ': 'I', 'Ｉ': 'I',
  'j': 'J', 'ｊ': 'J', 'Ｊ': 'J',
  'k': 'K', 'ｋ': 'K', 'Ｋ': 'K',
  'l': 'L', 'ｌ': 'L', 'Ｌ': 'L',
  'm': 'M', 'ｍ': 'M', 'Ｍ': 'M',
  'n': 'N', 'ｎ': 'N', 'Ｎ': 'N',
  'o': 'O', 'ｏ': 'O', 'Ｏ': 'O',
  'p': 'P', 'ｐ': 'P', 'Ｐ': 'P',
  'q': 'Q', 'ｑ': 'Q', 'Ｑ': 'Q',
  'r': 'R', 'ｒ': 'R', 'Ｒ': 'R',
  's': 'S', 'ｓ': 'S', 'Ｓ': 'S',
  't': 'T', 'ｔ': 'T', 'Ｔ': 'T',
  'u': 'U', 'ｕ': 'U', 'Ｕ': 'U',
  'v': 'V', 'ｖ': 'V', 'Ｖ': 'V',
  'w': 'W', 'ｗ': 'W', 'Ｗ': 'W',
  'x': 'X', 'ｘ': 'X', 'Ｘ': 'X',
  'y': 'Y', 'ｙ': 'Y', 'Ｙ': 'Y',
  'z': 'Z', 'ｚ': 'Z', 'Ｚ': 'Z',

  'ア': 'ｱ', 'イ': 'ｲ', 'ウ': 'ｳ', 'エ': 'ｴ', 'オ': 'ｵ',
  'カ': 'ｶ', 'キ': 'ｷ', 'ク': 'ｸ', 'ケ': 'ｹ', 'コ': 'ｺ',
  'サ': 'ｻ', 'シ': 'ｼ', 'ス': 'ｽ', 'セ': 'ｾ', 'ソ': 'ｿ',
  'タ': 'ﾀ', 'チ': 'ﾁ', 'ツ': 'ﾂ', 'テ': 'ﾃ', 'ト': 'ﾄ',
  'ナ': 'ﾅ', 'ニ': 'ﾆ', 'ヌ': 'ﾇ', 'ネ': 'ﾈ', 'ノ': 'ﾉ',
  'ハ': 'ﾊ', 'ヒ': 'ﾋ', 'フ': 'ﾌ', 'ヘ': 'ﾍ', 'ホ': 'ﾎ',
  'マ': 'ﾏ', 'ミ': 'ﾐ', 'ム': 'ﾑ', 'メ': 'ﾒ', 'モ': 'ﾓ',
  'ヤ': 'ﾔ', 'ユ': 'ﾕ', 'ヨ': 'ﾖ',
  'ラ': 'ﾗ', 'リ': 'ﾘ', 'ル': 'ﾙ', 'レ': 'ﾚ', 'ロ': 'ﾛ',
  'ワ': 'ﾜ', 'ヲ': 'ｵ', 'ン': 'ﾝ',

  'あ': 'ｱ', 'い': 'ｲ', 'う': 'ｳ', 'え': 'ｴ', 'お': 'ｵ',
  'か': 'ｶ', 'き': 'ｷ', 'く': 'ｸ', 'け': 'ｹ', 'こ': 'ｺ',
  'さ': 'ｻ', 'し': 'ｼ', 'す': 'ｽ', 'せ': 'ｾ', 'そ': 'ｿ',
  'た': 'ﾀ', 'ち': 'ﾁ', 'つ': 'ﾂ', 'て': 'ﾃ', 'と': 'ﾄ',
  'な': 'ﾅ', 'に': 'ﾆ', 'ぬ': 'ﾇ', 'ね': 'ﾈ', 'の': 'ﾉ',
  'は': 'ﾊ', 'ひ': 'ﾋ', 'ふ': 'ﾌ', 'へ': 'ﾍ', 'ほ': 'ﾎ',
  'ま': 'ﾏ', 'み': 'ﾐ', 'む': 'ﾑ', 'め': 'ﾒ', 'も': 'ﾓ',
  'や': 'ﾔ', 'ゆ': 'ﾕ', 'よ': 'ﾖ',
  'ら': 'ﾗ', 'り': 'ﾘ', 'る': 'ﾙ', 'れ': 'ﾚ', 'ろ': 'ﾛ',
  'わ': 'ﾜ', 'を': 'ｵ', 'ん': 'ﾝ',

  'ァ': 'ｱ', 'ぁ': 'ｱ', 'ｧ': 'ｱ',
  'ィ': 'ｲ', 'ぃ': 'ｲ', 'ｨ': 'ｲ',
  'ゥ': 'ｳ', 'ぅ': 'ｳ', 'ｩ': 'ｳ',
  'ェ': 'ｴ', 'ぇ': 'ｴ', 'ｪ': 'ｴ',
  'ォ': 'ｵ', 'ぉ': 'ｵ', 'ｫ': 'ｵ',

  'ヵ': 'ｶ', 'ゕ': 'ｶ',
  'ヶ': 'ｹ', 'ゖ': 'ｹ',

  'ャ': 'ﾔ', 'ゃ': 'ﾔ', 'ｬ': 'ﾔ',
  'ュ': 'ﾕ', 'ゅ': 'ﾕ', 'ｭ': 'ﾕ',
  'ョ': 'ﾖ', 'ょ': 'ﾖ', 'ｮ': 'ﾖ',
  'ッ': 'ﾂ', 'っ': 'ﾂ', 'ｯ': 'ﾂ',

  'ヴ': 'ｳﾞ', 'ゔ': 'ｳﾞ',
  'ガ': 'ｶﾞ', 'が': 'ｶﾞ',
  'ギ': 'ｷﾞ', 'ぎ': 'ｷﾞ',
  'グ': 'ｸﾞ', 'ぐ': 'ｸﾞ',
  'ゲ': 'ｹﾞ', 'げ': 'ｹﾞ',
  'ゴ': 'ｺﾞ', 'ご': 'ｺﾞ',
  'ザ': 'ｻﾞ', 'ざ': 'ｻﾞ',
  'ジ': 'ｼﾞ', 'じ': 'ｼﾞ',
  'ズ': 'ｽﾞ', 'ず': 'ｽﾞ',
  'ゼ': 'ｾﾞ', 'ぜ': 'ｾﾞ',
  'ゾ': 'ｿﾞ', 'ぞ': 'ｿﾞ',
  'ダ': 'ﾀﾞ', 'だ': 'ﾀﾞ',
  'ヂ': 'ﾁﾞ', 'ぢ': 'ﾁﾞ',
  'ヅ': 'ﾂﾞ', 'づ': 'ﾂﾞ',
  'デ': 'ﾃﾞ', 'で': 'ﾃﾞ',
  'ド': 'ﾄﾞ', 'ど': 'ﾄﾞ',
  'バ': 'ﾊﾞ', 'ば': 'ﾊﾞ',
  'ビ': 'ﾋﾞ', 'び': 'ﾋﾞ',
  'ブ': 'ﾌﾞ', 'ぶ': 'ﾌﾞ',
  'ベ': 'ﾍﾞ', 'べ': 'ﾍﾞ',
  'ボ': 'ﾎﾞ', 'ぼ': 'ﾎﾞ',
  'パ': 'ﾊﾟ', 'ぱ': 'ﾊﾟ',
  'ピ': 'ﾋﾟ', 'ぴ': 'ﾋﾟ',
  'プ': 'ﾌﾟ', 'ぷ': 'ﾌﾟ',
  'ペ': 'ﾍﾟ', 'ぺ': 'ﾍﾟ',
  'ポ': 'ﾎﾟ', 'ぽ': 'ﾎﾟ',

  '（': '(', '[': '(', '［': '(', '<': '(', '＜': '(', '【': '(',
  '）': ')', ']': ')', '］': ')', '>': ')', '＞': ')', '】': ')',

  '\u0335': '-', '\u0336': '-', '\u058A': '-', '\u05BE': '-',
  '\u2010': '-', '\u2011': '-', '\u2012': '-', '\u2013': '-',
  '\u2014': '-', '\u2015': '-', '\u207B': '-', '\u208B': '-',
  '\u2043': '-', '\u2212': '-', '\u2500': '-', '\u2501': '-',
  '\u2796': '-', '\u2E3A': '-', '\u2E3B': '-', '\u30FC': '-',
  '\u3127': '-', '\u3161': '-', '\u4E00': '-', '\uFE58': '-',
  '\uFE63': '-', '\uFF0D': '-', '\uFF70': '-',

  '．': '.',
  '　': ' ',
}

export function convertZengin(text: string) {
  return [...text].map(ch => convertZenginTable[ch] ?? ch).join('')
}
