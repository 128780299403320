
import {Type, Nullable, DateTimeS} from '@/aax/libs/typebox'

export const mm_producer = Type.Object({
  m_producer_id:                    Type.Integer(),   // Int
  producer_id:                      Type.Integer(),   // Int
  primary_producer_id:              Type.Integer(),   // Int
  client_id:                        Type.Integer(),   // Int
  name:                    Nullable(Type.String()),   // String?
  name_kana:               Nullable(Type.String()),   // String?
  short_name:              Nullable(Type.String()),   // String?
  short_name_kana:         Nullable(Type.String()),   // String?
  address1:                Nullable(Type.String()),   // String?
  address1_kana:           Nullable(Type.String()),   // String?
  address2:                Nullable(Type.String()),   // String?
  address2_kana:           Nullable(Type.String()),   // String?
  zip:                     Nullable(Type.String()),   // String?
  tel:                     Nullable(Type.String()),   // String?
  mobile:                  Nullable(Type.String()),   // String?
  email:                   Nullable(Type.String()),   // String?
  mobile_email:            Nullable(Type.String()),   // String?
  fax:                     Nullable(Type.String()),   // String?
  producing_area_id:       Nullable(Type.Integer()),  // Int?
  producing_area:          Nullable(Type.String()),   // String?
  process_markup_pct:      Nullable(Type.String()),   // Decimal?
  tokushima_dist_cost_pct: Nullable(Type.String()),   // Decimal?
  kansai_dist:             Nullable(Type.Boolean()),  // Boolean?
  kansai_dist_cost_pct:    Nullable(Type.String()),   // Decimal?
  container:               Nullable(Type.Boolean()),  // Boolean?
  seal:                    Nullable(Type.Boolean()),  // Boolean?
  remark:                  Nullable(Type.String()),   // String?
  note:                    Nullable(Type.String()),   // String?
  applicated:              Nullable(DateTimeS),       // DateTime?
  entered:                 Nullable(DateTimeS),       // DateTime?
  created:                 Nullable(DateTimeS),       // DateTime?
  modified:                Nullable(DateTimeS),       // DateTime?
  del:                              Type.Boolean(),   // Boolean
  biodiversity:            Nullable(Type.Boolean()),  // Boolean?
  exclude:                 Nullable(Type.Boolean()),  // Boolean?
})
