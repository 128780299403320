
// ダイアログ表示 (基底)

import {App, InjectionKey} from 'vue'

import {pluginInject} from '../libs/pluginInject'

//--------------------------------------------------------------

// (型) 依存構成
export type DialogDepends = ReturnType<typeof getDialogDepends>

// (型) オプション
export type DialogOptions = {}

// (型) インスタンス
export type DialogObject = ReturnType<typeof createDialog>

//--------------------------------------------------------------

// (内部) インジェクションキー
export const DialogKey: InjectionKey<DialogObject> = Symbol('dialog')

// (内部) 依存構成
export function getDialogDepends(app: App) {
  return {}
}

// (内部) インスタンス作成
export function createDialog(depends?: DialogDepends, options?: DialogOptions) {
  return {
    async alert  (args: {title?: string, message?: string}) {},
    async confirm(args: {title?: string, message?: string}) {},
    async prompt (args: {title?: string, message?: string}) {return ''},
  }
}

//--------------------------------------------------------------

// プラグインインストール
export const DialogPlugin = {
  install(app: App, options?: DialogOptions) {
    app.provide(DialogKey, createDialog(getDialogDepends(app), options))
  },
}

// プラグイン取得
export function useDialog() {
  return pluginInject(DialogKey)
}
