
<!-- PaymentStatement 支払明細 [一覧] -->

<template lang="pug">
.absolute-full._frame
  ._frame2
    ._medium._gray6 すきとく市
    hr
    ._medium ▼支払予定合計表▼
    hr
    table(border="0" align="center")
      tbody
        tr
          td 会員No
          td {{auth.auth.value?.producer_id}} {{auth.auth.value?.name}}
        tr
          td(
            v-if="paymentStatementList?.length == 0"
            colspan=2
          ) ファイルがありません

          template(v-else)
            td 対象年月
            td
              template(
                v-if="paymentStatementList == null"
              ) 読み込み中...

              select(
                v-else
                v-model="selected"
              )
                option(
                  v-for="entry of selectOptions"
                  :value="entry.value"
                ) {{entry.label}}

    template(v-if="selected != null")
      hr
      br
      button._print-button(
        type="button"
        @click="onPrint"
      ) 印刷する
      br
      br
      a(
        href="#"
        @click="onDownload"
      ) ダウンロードする
      br
      br

    hr
    button(
      type="button"
      @click="onExit"
    ) 終了
    hr
    div (C)すきとく市
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'
import {format, isValid} from 'date-fns'
import {useAxios} from '@/aax/plugins/Axios'
import {useAuth} from '../plugins/Auth'
import type {List} from '@/types/apis/PaymentStatements'

const axios = useAxios().$scoped()
const auth = useAuth()

const paymentStatementList = ref<List.RowT[]|null>(null)

const selectOptions = computed(() => paymentStatementList.value?.map(row => {
  const dateObj = new Date(row.processed as any)

  const filepath = row.filename?.match(/\/\d+-\d+\.pdf$/) ? row.filename?.replace(/\/(\d+)-(\d+).pdf$/, '/$1/$2') : row.filename?.replace(/.pdf$/, '')
  const producerName = row.producerName?.replaceAll(/\s/g, '')
  const producerId = row.primaryProducerId
  const clientId = row.clientId
  const dateStr = isValid(dateObj) ? format(dateObj, 'yyyy_MM') : ''
  const filename = `支払予定合計表-${clientId}-${producerId}-${dateStr}`

  return {
    label: format(dateObj, 'yyyy-MM'),
    value: `/_/payment-statements/${filepath}/${filename}.pdf`,
  }
}))

const selected = ref<any>(null)

onBeforeMount(async () => {
  axios.$cancel()

  paymentStatementList.value = await axios.$get('/_/api/payment-statements')

  if(selectOptions.value != null) {
    const entry = selectOptions.value[0]
    if(entry != null) {
      selected.value = entry.value
    }
  }
})

async function onDownload() {
  if(selected.value != null) {
    window.open(selected.value, '_blank')
  }
}

async function onPrint() {
  const frameKey = 'paymentStatementsListProducer-frame'
  let el: any = document.body.querySelector('#' + frameKey)
  if(el == null) {
    el = document.createElement('iframe')
    el.id = frameKey
    el.style.display = 'none'
    el.onload = function() {
        setTimeout(function() {
            el.focus()
            el.contentWindow.print()
        }, 1)
    }
    document.body.appendChild(el)
  }
  el.src = selected.value
}

async function onExit() {
  // windowを閉じる
  window.close()

  // もとのサイトに戻る
  setTimeout(() => {
    if(['sukitoku-pay.lvh.me', 'sukitoku-pay.d6.comman.co.jp'].includes(window.location.host)) {
      window.location.href = 'https://devel.sukitoku.com/report/menu/'
      return
    }
    window.location.href = 'https://sukitoku.com/report/menu/'
  }, 1000)
}
</script>

<style lang="sass" scoped>
._frame
  background: #f8f8e0
  color: #333333
  font-family: Verdana, "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif
  overflow: auto
  padding: 20px 0

  & button
    font-size: 10px

  & select
    font-family: Arial

._frame2
  margin: 0 auto
  text-align: center
  width: 60%

._medium
  font-size: medium

._gray6
  color: #666666

._print-button
  font-size: 14px !important
  line-height: 14px
</style>
