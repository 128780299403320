<template lang="pug">
q-select.x-field-client-offset(
  standout="aq-field-standout"
  options-dense
  emit-value map-options
  fill-input hide-selected use-input input-debounce="0"
  :class="{'x-field-client-offset--loading': loading}"
  :loading="loading"
  :modelValue="modelValue"
  :options="options"
  @filter="onFilter"
  @update:modelValue="v => emit('update:modelValue', v)"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed, onBeforeMount} from 'vue'
import type {QSelect} from 'quasar'

import {ClientOffsetSelect} from '@/types/apis/ClientOffsets'

// inject

import {useAxios} from '@/aax/plugins/Axios'

const axios = useAxios().$scoped()

// props

const props = defineProps<{
  modelValue?: string|null,
}>()

// emits

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|null): void,
}>()

// state

type OptionsT = {
  value: string,
  label: string,
}

const clients = ref<OptionsT[]>([])
const loading = ref(true)

const filter = ref('')
const options = computed(() =>
  filter.value === ''
    ? clients.value
    : clients.value.filter(c => c.label.includes(filter.value))
)

// events

onBeforeMount(async () => {
  try {
    const select = await axios.$get<ClientOffsetSelect.RowT[]>('/_/api/client-offsets/@select')
    clients.value = select.map(row => ({
      value: row.id,
      label: row.name ?? '',
    }))
  }
  finally {
    loading.value = false
  }
})

const onFilter: QSelect['onFilter'] = (value, update) => {
  update(() => {
    filter.value = value
  })
}

// FIXME: q-selectにoptions-dense をつけるとバグる
</script>

<!----------------------------------------------------------------------------->

<script lang="ts">
import '@/aax/components-quasar/AqStyles.sass'
</script>

<style lang="sass">
.x-field-client-offset
  .q-field__native
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &.x-field-client-offset--loading
    .q-field__native
      visibility: hidden
</style>
