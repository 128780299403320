
import {App, InjectionKey} from 'vue'

import {pluginInject} from '@/aax/libs/pluginInject'
import {Role, hasRoles, getClientId} from '@/types/plugins/Auth'

// 依存プラグイン
import {AuthKey as ParentKey, getAuthDepends, createAuth as createParent, loadIntro} from '@/aax/plugins/Auth__saml'
import type {AuthDepends, AuthOptions} from '@/aax/plugins/Auth__saml'
export * from '@/aax/plugins/Auth__saml'

//--------------------------------------------------------------

// (型) インスタンス
export type AuthObject = ReturnType<typeof createAuth>

//--------------------------------------------------------------

// (内部) インジェクションキー
export const AuthKey: InjectionKey<AuthObject> = ParentKey

// (内部) インスタンス作成
export function createAuth(depends: AuthDepends, options?: AuthOptions) {
  const parent = createParent(depends, options)

  const obj = {
    ...parent,

    hasRoles(...roles: Role[]) {
      return hasRoles(obj.auth.value, ...roles)
    },

    getClientId(clientId?: any) {
      return getClientId(obj.auth.value, clientId)
    },
  }

  return obj
}

//--------------------------------------------------------------

// プラグインインストール
export async function loadAuthPlugin(app: App, options?: AuthOptions) {
  const obj = createAuth(getAuthDepends(app), options)
  await loadIntro(obj)

  return {
    install(app: App) {
      app.provide(AuthKey, obj)
    },
  }
}

// プラグイン取得
export function useAuth() {
  return pluginInject(AuthKey)
}
