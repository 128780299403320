
// ダイアログ表示 (quasar使用)

import {App} from 'vue'

import {Dialog} from 'quasar'

import {DialogKey, getDialogDepends, createDialog as createParent} from './Dialog'
import type {DialogDepends, DialogOptions, DialogObject} from './Dialog'
export * from './Dialog'

//--------------------------------------------------------------

// (型) 依存構成
// DialogDepends

// (型) オプション
// DialogOptions

// (型) インスタンス
// DialogObject

//--------------------------------------------------------------

// (内部) インジェクションキー
// DialogKey

// (内部) 依存構成
// getDialogDepends

// (内部) インスタンス作成
export function createDialog(depends: DialogDepends, options?: DialogOptions): DialogObject {
  return {
    ...createParent(depends, options),

    alert(args: {title?: string, message?: string}): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          Dialog.create({
            ...args,
            persistent: true,
          })
          .onOk(resolve)
        }
        catch(e) {reject(e)}
      })
    },

    confirm(args: {title?: string, message?: string}): Promise<void> {
      return new Promise((resolve, reject) => {
        try {
          Dialog.create({
            ...args,
            cancel: true,
          })
          .onOk(resolve)
          .onCancel(reject)
        }
        catch(e) {reject(e)}
      })
    },

    prompt(args: {title?: string, message?: string}): Promise<string> {
      return new Promise((resolve, reject) => {
        try {
          Dialog.create({
            ...args,
            cancel: true,
            noBackdropDismiss: true,
            prompt: {model: ''},
          })
          .onOk(resolve)
          .onCancel(reject)
        }
        catch(e) {reject(e)}
      })
    },
  }
}

//--------------------------------------------------------------

// プラグインインストール
export const DialogPlugin = {
  install(app: App, options?: DialogOptions) {
    app.provide(DialogKey, createDialog(getDialogDepends(app), options))
  },
}

// プラグイン取得
// useDialog
