/* eslint-disable no-console */

type AnyFunction = (...args: any[]) => void

export default {
  former: {...console},

  override(name: string, fn: AnyFunction) {
    if(!this.former.hasOwnProperty(name)) {
      this.former.warn('cannot override: console.' + name)
      return
    }

    if((this.former as any)[name] !== (console as any)[name]) {
      this.former.warn('multiple override: console.' + name)
      return
    }

    ;(console as any)[name] = fn
  },

  unoverride(name: string) {
    if(!this.former.hasOwnProperty(name)) {
      this.former.warn('cannot unoverride: console.' + name)
      return
    }

    ;(console as any)[name] = (this.former as any)[name]
  },
}
