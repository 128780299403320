
// 共用: aax-fastify, aax-vue3

import {Type, Static, SqlColumnS} from './typebox'

//

export const S = Type.Object({
  i:  Type.Optional(Type.Integer({minimum: 0})), // OFFSET
  c:  Type.Optional(Type.String()),              // CURSOR
  n:  Type.Optional(Type.Integer({minimum: 1})), // LIMIT
  w:  Type.Optional(Type.String()),              // WHERE text
  wc: Type.Optional(SqlColumnS),                 // WHERE column
  o:  Type.Optional(SqlColumnS),                 // ORDERBY column
  od: Type.Optional(Type.RegEx(/^d$/)),          // ORDERBY direction
})
export type T = Static<typeof S>
