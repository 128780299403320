
// prisma: ひと月の範囲を作る

import {parse, parseISO, isValid, isBefore, startOfMonth, endOfMonth} from 'date-fns'

export function makeMonthRange(month?: Date|string|null) {
  if(month != null) {
    const obj = month instanceof Date ? startOfMonth(month) : parse(month, 'yyyyMM', 0)
    if(isValid(obj)) return {gte: obj, lte: endOfMonth(obj)}
  }
}

export function makeMonthRangeTest(month?: Date|string|null) {
  const range = makeMonthRange(month)
  if(range != null) return (date?: Date|string|null) => {
    if(date == null) return false
    const obj = date instanceof Date ? date : parseISO(date)
    return isValid(obj)
      && !isBefore(obj, range.gte)
      && !isBefore(range.lte, obj)
  }
}
