
import {Type, Nullable, DateTimeS} from '@/aax/libs/typebox'

export const mm_client = Type.Object({
  client_id:                        Type.Integer(),  // Int
  type:                    Nullable(Type.Integer()), // Int?
  name:                    Nullable(Type.String()),  // String?
  name_kana:               Nullable(Type.String()),  // String?
  address1:                Nullable(Type.String()),  // String?
  address1_kana:           Nullable(Type.String()),  // String?
  address2:                Nullable(Type.String()),  // String?
  address2_kana:           Nullable(Type.String()),  // String?
  zip:                     Nullable(Type.String()),  // String?
  tel:                     Nullable(Type.String()),  // String?
  mobile:                  Nullable(Type.String()),  // String?
  leader_name:             Nullable(Type.String()),  // String?
  leader_name_kana:        Nullable(Type.String()),  // String?
  leader_tel:              Nullable(Type.String()),  // String?
  acting_leader_name:      Nullable(Type.String()),  // String?
  acting_leader_name_kana: Nullable(Type.String()),  // String?
  acting_leader_tel:       Nullable(Type.String()),  // String?
  note:                    Nullable(Type.String()),  // String?
  created:                 Nullable(DateTimeS),      // DateTime?
  modified:                Nullable(DateTimeS),      // DateTime?
})
