
export type Rules = ((value: any) => boolean|string|Promise<boolean|string>)[]

export async function testRules(rules: Rules|null|undefined, value: any) {
  if(rules != null) {
    for(const rule of rules) {
      const result = await rule(value)
      if(result !== true) return false
    }
  }
  return true
}
