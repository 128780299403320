
import type {QScrollAreaProps} from 'quasar'

export type QScrollAreaOnScrollInfo = Parameters<NonNullable<QScrollAreaProps['onScroll']>>[0]

//

export const AqRootMenuBtnKey = Symbol('aq-root-menu-btn')

//

declare module 'vue-router' {
  interface RouteMeta {
    title?:  string
    icon?:   string
    parent?: string
  }
}
