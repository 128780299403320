<template lang="pug">
aq-page(fixed)
  .fit.overflow-auto.q-pa-lg
    .column.justify-center.items-center.q-gutter-lg
      //- x-year-month(
      //-   v-model="yearMonth"
      //-   dense no-clearable
      //- )

      x-btn(
        color="negative"
        :disable="isDisableG || isDisable1"
        @click="createPaymentStatements"
      ) 先月の【支払予定合計表】を再作成する

      x-btn(
        color="negative"
        :disable="isDisableG || isDisable2"
        @click="createClientPaymentStatements"
      ) 先月の【団体支払明細】を再作成する

      x-btn(
        color="negative"
        :disable="isDisableG || isDisable1"
        @click="createPaymentData"
      ) 先月の【支払データ（銀行振込データ）】を再作成する

      div(v-if="isDisableG") 処理中です
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import { addDays, addMonths, isBefore, parse, startOfMonth } from 'date-fns';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useAxios } from '@/aax/plugins/Axios'
import { useDialog } from '@/aax/plugins/Dialog'

const axios = useAxios().$scoped()
const dialog = useDialog()

const yearMonth = ref<string|null>(null)

const currentDate = ref(new Date())

function limitDate(date: string|null, day: number) {
  return addDays(addMonths(startOfMonth(parse(date ?? '', 'yyyyMM', 0)), 1), day)
}

const isDisableG = ref(true)
const isDisable1 = computed(() => isBefore(currentDate.value, limitDate(yearMonth.value, 4)))
const isDisable2 = computed(() => isBefore(currentDate.value, limitDate(yearMonth.value, 4)))

let timer: NodeJS.Timer

onMounted(async () => {
  await updateReady()

  timer = setInterval(async () => {
    await updateReady()
  }, 10000)
})

onBeforeUnmount(async () => {
  clearInterval(timer)
})

async function updateReady() {
  try {
    isDisableG.value = !Boolean(await axios.$get('/_/api/commands/@ready'))
  } catch(e) {}
}

async function confirm() {
  await dialog.confirm({
    title: '作成の確認',
    message: 'すでに作成されたファイルとデータベースエントリを再作成します。 処理内容について理解していない場合は絶対に実行しないでください。 本当によろしいですか？'
  })
}

async function createPaymentStatements() {
  await confirm()

  try {
    await axios.$get(`/_/api/commands/create-payment-statements/${yearMonth.value ?? ''}`)
  }
  finally {
    await updateReady()
  }
}

async function createClientPaymentStatements() {
  await confirm()

  try {
    await axios.$get(`/_/api/commands/create-client-payment-statements/${yearMonth.value ?? ''}`)
  }
  finally {
    await updateReady()
  }
}

async function createPaymentData() {
  await confirm()

  try {
    await axios.$get(`/_/api/commands/create-payment-data/${yearMonth.value ?? ''}`)
  }
  finally {
    await updateReady()
  }
}
</script>
