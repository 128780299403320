
import {Type, Static, Nullable, DateTimeS} from '@/aax/libs/typebox'

export const AuthS = Type.Object({
  member_id:                    Type.Integer(),
  login_id:            Nullable(Type.String()),
  role:                Nullable(Type.Integer()),
  client_name:         Nullable(Type.String()),
  client_id:           Nullable(Type.Integer()),
  producer_id:         Nullable(Type.Integer()),
  name:                Nullable(Type.String()),
  kana:                Nullable(Type.String()),
  email:               Nullable(Type.String()),
  mobile:              Nullable(Type.String()),
  last_login:          Nullable(DateTimeS),
  ip:                  Nullable(Type.String()),
  host:                Nullable(Type.String()),
  user_agent:          Nullable(Type.String()),
  created:             Nullable(DateTimeS),
  modified:            Nullable(DateTimeS),
  store_id:            Nullable(Type.Integer()),
  tel:                 Nullable(Type.String()),
  mobile_tel:          Nullable(Type.String()),
  marked:              Nullable(Type.Integer()),
  primary_producer_id: Nullable(Type.Integer()),
})
export type AuthT = Static<typeof AuthS>

export const IntroS = Type.Object({
  ver: Type.Optional(Type.String()),
  auth: Type.Optional(AuthS),
})
export type IntroT = Static<typeof IntroS>

// 権限チェック
export type Role = 'admin'|'client'|'producer'

export function hasRoles(auth: any, ...roles: Role[]) {
  const auth_role = auth?.role as number|null|undefined
  if(auth_role == null) return false
  const authRole = {1: 'admin', 2: 'producer', 3: 'client'}[auth_role]
  return roles.some(role => role === authRole)
}

// 団体IDを取得 (clientId指定時はそれを扱う権限があればそれを返し、なければ-1を返す)
export function getClientId(auth: any, clientId?: any) {
  const id = clientId == null ? undefined : parseInt(clientId, 10)
  const auth_role = auth?.role as number|null|undefined
  if(auth_role === 1) return id
  const auth_client_id = (auth?.client_id as number|null|undefined) ?? undefined
  return id != null && id !== auth_client_id ? -1 : auth_client_id
}
