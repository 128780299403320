
// ClientOffsetValue 相殺金額

import {Type, Static, StringLiteralArray, Nullable, partialOf, CuidS, DateTimeS, ApiSchema, StaticApi} from '@/aax/libs/typebox'

// 共通

export const MonthS = Type.RegEx(/^\d{6}$/)

export namespace Common {

  export const RowS = Type.Object({       // ClientOffsetValue
    id:                 CuidS,            // String    ID

    processed: Nullable(DateTimeS),       // DateTime? 相殺日
    quantity:  Nullable(Type.Integer()),  // Int?      個数
    value:     Nullable(Type.Integer()),  // Int?      金額
    remarks:   Nullable(Type.String()),   // String?   備考

    carry:    Nullable(Type.Boolean()),  // Boolean?  繰越
    carried:  Nullable(DateTimeS),       // DateTime? 繰越の結果、作成された項目か？
    shortage: Nullable(Type.Boolean()),  // Boolean?  金額不足で相殺できなかった

    clientOffsetId:     Type.String(),    // String    相殺項目ID      (rel: ClientOffset)
    producerId:         Type.Integer(),   // Int       生産者ID        (rel: sukitoku_db.mm_producer.producer_id)

    clientId:         Type.Optional(Type.Integer()),           // Int?     外部団体のみ: 団体ID
    clientName:       Type.Optional(Nullable(Type.String())),  // String?  外部団体のみ: 団体名称
    producerName:     Type.Optional(Nullable(Type.String())),  // String?  外部団体のみ: 生産者名称
  })

  export const ParamsS = Type.Object({
    month:     Type.Optional(MonthS),
    client_id: Type.Optional(Type.Integer()),
  })
}

// 一括取得

export namespace Get {

  export const RowS = Common.RowS
  export type RowT = Static<typeof RowS>

  export const S = ApiSchema({
    params:   Common.ParamsS,
    response: {'2xx': Type.Array(RowS)},
  })
  export type T = StaticApi<typeof S>
}

// 一括更新

export namespace Put {

  export const Readonly = StringLiteralArray(['clientId', 'clientName', 'producerName'])
  export const Partial = StringLiteralArray(['id', 'clientId', 'clientName', 'producerName'])

  export const RowS = partialOf(Common.RowS, Partial)
  export type RowT = Static<typeof RowS>

  export const S = ApiSchema({
    params: Common.ParamsS,
    body:   Type.Array(RowS),
  })
  export type T = StaticApi<typeof S>
}
