<template lang="pug">
q-btn(
  v-bind="$attrs"
  :color="color"
)
  slot
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {computed} from 'vue'

const props = defineProps<{
  type?: string,
}>()

const color = computed(() => (props.type == null ? undefined : ({
  cancel:  'negative',
  confirm: 'primary',
})[props.type]))
</script>
