
<!-- テーブル形式フォームのカラム入力: 汎用テキスト入力 -->

<template lang="pug">
q-input(
  v-bind="$attrs"
  dense standout="aq-field-standout"
  :class="{'x-col__error': error}"
  :debounce="500"
  :modelValue="modelValue"
  @update:modelValue="onUpdate"
)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, watchEffect} from 'vue'
import {isString} from 'lodash-es'

import {testRules} from './XColRules'
import type {Rules} from './XColRules'

// props

const props = defineProps<{
  modelValue: string|number|null,
  number?: boolean,
  rules?: Rules,
}>()

// emits

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|number|null): void,
}>()

// バリデーション

const error = ref(false)

watchEffect(async () => {
  error.value = !await testRules(props.rules, props.modelValue)
})

// 更新

async function onUpdate(value: string|number|null) {
  if(props.number && isString(value)) {
    const number = parseInt(value, 10)
    emit('update:modelValue', isNaN(number) ? null : number)
  }
  else emit('update:modelValue', value)
}
</script>

<!----------------------------------------------------------------------------->

<style lang="sass">
.x-col__error .q-field__control
  background: rgba(255, 0, 0, .20) !important

.x-col__error.q-field--dark .q-field__control
  background: rgba(255, 64, 64, .28) !important
</style>
