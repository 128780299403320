
// Bank 銀行

import {Type, Static, StringLiteralArray, Nullable, partialOf, CuidS, DateTimeS} from '@/aax/libs/typebox'

// API: CRUD

export namespace Crud {

  // project://app/prisma/schema.prisma#15

  export const S = Type.Object({
    id:                CuidS,       // String    ID

    code: Nullable(Type.String()),  // String?   金融機関コード
    name: Nullable(Type.String()),  // String?   名称
    kana: Nullable(Type.String()),  // String?   カナ

    created:           DateTimeS,   // DateTime  登録日
    modified:          DateTimeS,   // DateTime  更新日
    deleted:  Nullable(DateTimeS),  // DateTime? 削除日
  })

  //

  export const PrimaryKey = 'id'
  export const Readonly = StringLiteralArray(['id', 'created', 'modified', 'deleted'])

  //

  export const SummaryS = S

  export const WriteS = partialOf(S, Readonly)
  export type WriteT = Static<typeof WriteS>
}
