
<!-- PaymentInfo 支払情報 [一覧] -->

<template lang="pug">
//- 設定
x-list(
  :columns          = "columns"
  api-url           = "/_/api/payment-infos"
  api-count-url     = "/_/api/payment-infos/@count"
  row-key           = "id"
  :rows-per-load    = "100"
  selection         = "single"
  :additional-query = "additionalQuery"
  next-to           = "paymentInfosDetail"
  :menus            = "['update']"
)
  template(#append-filter)
    x-target-client(v-model="searchClientId" dense)
</template>

<!----------------------------------------------------------------------------->

<script setup lang="ts">
import {ref, computed} from 'vue'

import {getIntQuery} from '@/aax/libs/vue-router'
import type {ColumnsT} from '@/aax/components-quasar/AqLazyTable.vue'

import {FieldSend} from '@/types/apis/PaymentInfos'

// injects

import {useRoute} from 'vue-router'

const route = useRoute()

// カラムUI定義
const columns: ColumnsT = [
  {
    label: '代表生産者ID',
    name: 'mm_producer.primary_producer_id',
    field: row => row.mm_producer?.primary_producer_id,
    // sortable: true, // FIXME: nameが合致しないので、だめ
    headerClasses: 'q-table--col-auto-width',
  },

  {
    label: '代表生産者氏名',
    name: 'mm_producer.name',
    field: row => row.mm_producer?.name,
  },

  {
    label: '団体ID',
    name: 'mm_client.client_id',
    field: row => row.mm_client?.client_id,
    // sortable: true, // FIXME: nameが合致しないので、だめ
    headerClasses: 'q-table--col-auto-width',
  },

  {
    label: '団体名',
    name: 'mm_client.name',
    field: row => row.mm_client?.name,
  },

  {
    label: '口座番号', // FIXME: 隠すこと
    name: 'no',
    headerClasses: 'q-table--col-auto-width',
  },

  // {
  //   label: '送付',
  //   name: 'send',
  //   fieldMap: FieldSend,
  //   headerClasses: 'q-table--col-auto-width',
  // },
]

// 団体ID検索
const searchClientId = ref(getIntQuery(route.query.client_id))

const additionalQuery = computed(() => {
  return {client_id: searchClientId.value}
})

</script>
