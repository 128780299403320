
import {createApp} from 'vue'

import App from './App.vue'
import routes from './routes'

import {QuasarPlugin}       from './quasar'
import {ComponentsPlugin}   from './components/plugin'
import {EventBusPlugin}     from './aax/plugins/EventBus'
import {ToastPlugin}        from './aax/plugins/Toast__quasar'
import {DialogPlugin}       from './aax/plugins/Dialog__quasar'
import {ReportPlugin}       from './aax/plugins/Report'
import {JsHooksPlugin}      from './aax/plugins/JsHooks'
import {ErrorHandlerPlugin} from './aax/plugins/ErrorHandler'
import {AxiosPlugin}        from './aax/plugins/Axios'
import {loadAuthPlugin}     from './plugins/Auth'

;(async () => {
  const app = createApp(App)
  .use(QuasarPlugin)
  .use(ComponentsPlugin)
  .use(EventBusPlugin)
  .use(ToastPlugin)
  .use(DialogPlugin)
  .use(ReportPlugin)
  .use(JsHooksPlugin)
  .use(ErrorHandlerPlugin)
  .use(AxiosPlugin, {
    baseURL: import.meta.env.BASE_URL,
    timeout: 10000,
  })

  const AuthPlugin = await loadAuthPlugin(app)

  app
  .use(AuthPlugin)
  .use(routes)
  .mount('#app')
})()
