
import {onBeforeMount, onBeforeUnmount} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {useDialog} from '@/aax/plugins/Dialog'

export function leaveGuard(guardFn: () => boolean) {

  const dialog = useDialog()

  onBeforeRouteLeave(async () => {
    if(guardFn()) {
      try {
        await dialog.confirm({message: '編集中の内容が破棄されます。よろしいですか？'})
      }
      catch(e) {
        if(e === undefined) return false
        throw e
      }
    }
    return true
  })

  function guardBeforeUnload(e: any) {
    if(guardFn()) {
      e.preventDefault()
      e.returnValue = '編集中の内容が破棄されます。よろしいですか？'
    }
  }

  onBeforeMount(() => {
    window.addEventListener('beforeunload', guardBeforeUnload)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', guardBeforeUnload)
  })
}
