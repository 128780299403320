
// 共用: aax-fastify, aax-vue3

import {Type, Static, TLiteral, TNull, TObject, TProperties, TRef, TSchema, TUnion, SelectablePropertyKeys, StringOptions, StringKind} from '@sinclair/typebox'
export {Type, Static, TLiteral, TNull, TObject, TProperties, TRef, TSchema, TUnion, SelectablePropertyKeys, StringOptions, StringKind}
export * from '@sinclair/typebox'

// 文字列配列 values から union 型の配列を作成する
// https://stackoverflow.com/a/52085658
export function StringLiteralArray<T extends string>(values: T[]) {
  return values
}

// schema を null も指定可能にする
// https://github.com/sinclairzx81/typebox#openapi
export function Nullable<T extends TSchema>(schema: T): TUnion<[T, TNull]> {
  return {...schema, nullable: true} as any
}

// 文字列配列 values から union 型のスキームを作成する
// https://github.com/sinclairzx81/typebox#openapi
type IntoStringUnion<T> = {[K in keyof T]: T[K] extends string ? TLiteral<T[K]>: never}
export function StringUnion<T extends string[]>(values: [...T]): TUnion<IntoStringUnion<T>> {
  return {enum: values} as any
}

// 指定したプロパティへoptionalを付加する
export function partialOf<T extends TObject<TProperties> | TRef<TObject<TProperties>>, K extends SelectablePropertyKeys<T>[]>(object: T, keys: [...K]) {
  return Type.Intersect([Type.Partial(Type.Pick(object, keys)), Type.Omit(object, keys)])
}

// 指定したプロパティからoptionalを取り除く
export function requiredOf<T extends TObject<TProperties> | TRef<TObject<TProperties>>, K extends SelectablePropertyKeys<T>[]>(object: T, keys: [...K]) {
  return Type.Intersect([Type.Required(Type.Pick(object, keys)), Type.Omit(object, keys)])
}

// 指定したプロパティ以外へoptionalを付加する
export function partialExcept<T extends TObject<TProperties> | TRef<TObject<TProperties>>, K extends SelectablePropertyKeys<T>[]>(object: T, keys: [...K]) {
  return Type.Intersect([Type.Partial(Type.Omit(object, keys)), Type.Pick(object, keys)])
}

// 指定したプロパティ以外からoptionalを取り除く
export function requiredExcept<T extends TObject<TProperties> | TRef<TObject<TProperties>>, K extends SelectablePropertyKeys<T>[]>(object: T, keys: [...K]) {
  return Type.Intersect([Type.Required(Type.Omit(object, keys)), Type.Pick(object, keys)])
}

// cuid 型のスキーム
export const CuidS = Type.RegEx(/^c[0-9a-z]{24,29}$/)

// DateTime 型のスキーム (ISO8601 yyyy-mm-ddThh:ii:ssZ 形式)
export interface TDateTime extends TSchema, StringOptions<string> {
  $static: string|Date;
  kind: typeof StringKind;
  type: 'string';
}
export const DateTimeS = Type.String({format: 'date-time'}) as TDateTime

// sql カラム名に適応するスキーム
export const SqlColumnS = Type.RegEx(/^[A-Za-z_]\w{0,62}$/)

// fastify APIの空スキーマ定義
export type TApiSchema = {
  headers:     TSchema,
  params:      TSchema,
  querystring: TSchema,
  body:        TSchema,
  response:    {'2xx': TSchema},
}

const ApiSchemaBase = {
  headers:     Type.Object({}),
  params:      Type.Object({}),
  querystring: Type.Object({}),
  body:        Type.Null(),
  response:    {'2xx': Type.String({maxLength: 0})},
}

export function ApiSchema<T extends Partial<TApiSchema>>(schema: T): T & Omit<typeof ApiSchemaBase, keyof T> {
  return {
    ...ApiSchemaBase,
    ...schema,
  }
}

// fastify APIのスキーマから型を作成する
export type StaticApi<ST extends TApiSchema> = {
  Headers:     Static<ST['headers']>,
  Params:      Static<ST['params']>,
  Querystring: Static<ST['querystring']>,
  Body:        Static<ST['body']>,
  Reply:       Static<ST['response']['2xx']>,
}
